<template>
  <v-menu
    :nudge-right="40"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="dateFormatted"
        v-mask="getMask"
        v-on="on" 
        append-icon="event"
        :label="label"
        :disabled="disabled"
        :error-messages="error"
        :hint="hint"
        :messages="messages"
        :rules="rules"
        :required="required"
        :prefix="prefix"
        :suffix="suffix"
        @blur="parseDate(dateFormatted, true)"
      ></v-text-field>
    </template>
    <v-container align-baseline>
    <v-layout row wrap fill-height fluid>
      <v-flex v-if="type !== 'time'">
        <v-date-picker
          v-model="date"
          :title-date-format="titleDateFormat"
          :header-date-format="headerDateFormat"
          :month-format="monthFormat"
          :weekday-format="weekdayFormat"
          :no-title="noTitle"
          @input="close(1)"
        ></v-date-picker>
      </v-flex>
      <v-flex v-if="type !== 'date'" class="mydatetime">
        <v-time-picker
          ref="timePicker"
          v-model="time"
          format="24hr"
          scrollable
          use-seconds
          :no-title="noTitle"
          @change="close(2)"
        ></v-time-picker>
      </v-flex>
    </v-layout>
    </v-container>
  </v-menu>
</template>

<script>
  // 
  // nao está funcionando para datetime
  //
  import { mask } from 'vue-the-mask'

  export default {
    directives: {
      mask,
    },

    props: [
      'editedItem',
      'editedKey',
      'type',
      'label',
      'disabled',
      'hint',
      'errorMessages',
      'noTitle',
      'messages',
      'rules',
      'required',
      'prefix',
      'suffix'
    ],

    data: () => ({
      date: '',
      time: '',
      dateFormatted: '',
      error: ''
    }),

    computed: {
      getMask () {
        if (this.type === 'datetime')
          return '##/##/#### ##:##'
        else if (this.type === 'date')
          return '##/##/####'
        else
          return '##:##'
      }
    },

    watch: {
      date () {
        this.dateFormatted = this.formatDatetime(this.date, true)
        this.parseDate(this.dateFormatted, true)
      },

      time () {
        this.dateFormatted = this.formatDatetime(this.date + ' ' + this.time, true)
      },

      errorMessages () {
        this.error = this.errorMessages
      },

      editedItem: {
        handler: function (item) {
          let value = item[this.editedKey]
          if (value) {
            this.dateFormatted = this.formatDatetime(value, false)
            this.parseDate(this.dateFormatted, false)
          } else {
            this.dateFormatted = ''
            this.date = null
          }
        },
        deep: true
      }
    },

    methods: {
      close (mode) {
        if (mode === 2) {
          this.$refs.timePicker.selectingHour = true
        } 
      },

      formatDatetime (date, edit) {
        if (!date) 
          return null
        this.error = ''
        if (this.type === 'datetime') {
          let [year, month, day] = date.split('-')
          let [dd, time] = day.split(' ')
          if (edit && time)
            this.editedItem[this.editedKey] = `${year}-${month}-${dd} ${time}` // :00`
          return `${dd}/${month}/${year} ${time}`
        } else if (this.type === 'date') {
          let [year, month, day] = date.split('-')
          day = day.split(' ')[0]
          if (edit)
            this.editedItem[this.editedKey] = `${year}-${month}-${day}`
          return `${day}/${month}/${year}`
        } else {
          if (edit)
            this.editedItem[this.editedKey] = date
          return date
        }
      },

      parseDate (value, edit) {
        let day, month, year, aammdd, ok, yy, time
        if (!value) {
          if (edit && this.editedItem[this.editedKey]) {
            this.editedItem[this.editedKey] = null
            this.date = null
          }
        } else {
          if (this.type === 'datetime') {
            [day, month, year] = value.split('/')
            if (year === undefined || year.length < 4) {
              ok = 'Invalid Date'
            } else {
              [yy, time] = year.split(' ')
              aammdd = `${yy}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
              ok = new Date(aammdd + ' ' + time)
            }
            if (ok.toString() === 'Invalid Date') {
              this.error = 'Data/hora [' + value + '] inválida'
              this.dateFormatted = null
            } else {
              this.date = aammdd
              this.time = time.substr(0, 5)
              this.error = ''
              if (edit)
                this.editedItem[this.editedKey] = aammdd + ' ' + time
            }
          } else if (this.type === 'date') {
            [day, month, year] = value.split('/')
            if (year === undefined || year.length < 4) {
              ok = 'Invalid Date'
            } else {
              aammdd = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
              ok = new Date(aammdd)
            }
            if (ok.toString() === 'Invalid Date') {
              this.error = 'Data [' + value + '] inválida'
              this.dateFormatted = null
            } else {
              this.date = aammdd
              this.error = ''
              if (edit)
                this.editedItem[this.editedKey] = aammdd // + ' 00:00:00'
            }
          } else {
            time = this.validTime(value)
            if (time) {
              this.time = time
              this.error = ''
              if (edit)
                this.editedItem[this.editedKey] = time //  + ':00'
            } else {
              this.error = 'Hora [' + value + '] inválida'
              this.dateFormatted = null
            }
          }
        }
      },

      validTime (value) {
        const values = value.split(':')
        let h = -1
        let m = -1
        if (values.length >= 1) {
          h = parseInt(values[0])
        }
        if (values.length >= 2) {
          m = parseInt(values[1])
        }
        if ((h < 0) || (m < 0) || (h > 24) || (m > 59)) {
          this.error = 'Hora [' + value + '] é inválida'
          this.dateFormatted = null
          return false
        }
        return String(h).padStart(2, '0') + ':' + String(m).padStart(2, '0')
      },

      titleDateFormat (date) {
        const [year, month, day] = date.split('-')
        let d = new Date(year, parseInt(month) - 1, day)
        const week = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
        return week[d.getDay()] + ', ' + String(d.getDate()) + ' ' + months[d.getMonth()]
      },

      headerDateFormat (date) {
        const [year, month] = date.split('-')
        if (month === undefined)
          return year
        const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
        return months[parseInt(month) - 1] + ' ' + year
      },

      monthFormat (date) {
        const month = date.split('-')[1]
        const months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ']
        return months[parseInt(month) - 1]
      },

      weekdayFormat (date) {
        const [year, month, day] = date.split('-')
        let d = new Date(year, parseInt(month) - 1, day)
        const week = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
        return week[d.getDay()]
      }
    }
  }
</script>

<style scoped>
  .container {
    padding: 0;
  }
  .v-text-field {
    font-size: 0.8rem;
  }
  .mydatetime .v-picker__title {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .mydatetime .v-picker__body {
    height: 286px;
  }
</style>
