<template>
  <div @click="closeSearchBox" :style="'opacity:' + opacity + ';'">
    <div v-if="joinLink" />
    <!--
      Toolbar
    -->
    <div v-if="titlable && showGrid && !editing" class="mx-5">
      <v-toolbar
        dense
        :color="isDark() ? '#424242' : 'indigo'"
        :dark="!isDark()"
        :class="'scrolling-wrapper mt-5 mb-2 my-border ' + (isChild() ? 'elevation-0' : 'elevation-2')"
        :style="myCreatable || titlable ? '' : 'display: none;'"
      >
        <v-btn icon text v-if="titlable && iconFavorite && (!isChild()) && (!specialSetup)" @click="addFavorite">
          <v-icon v-if="isFavorite" color="yellow">mdi-star</v-icon>
          <v-icon v-else>mdi-star-outline</v-icon>
        </v-btn>
        <v-toolbar-title class="scrolling-item" v-if="titlable">{{myCaption || caption}}</v-toolbar-title>
        <v-divider v-if="titlable" class="mx-2" inset vertical />
        <slot
          name="titleGrid"
          :main="this"
        ></slot>
        <span class="scrolling-item" style="min-width: 500px;">
          <v-combobox
            ref="comboboxSearch"
            v-if="mySearchable"
            :placeholder="placeHolder ? placeHolder : 'Pesquise' + (selector.searchBy ? ' por { ' + selector.searchBy + ' }' : '')"
            v-model="searchText"
            :loading="searchLoading"
            :items="searchItems"
            prepend-icon="mdi-magnify"
            :error-messages="searchError"
            :disabled="modified()"
            small-chips
            clearable
            deletable-chips
            multiple
            hide-selected
            dense
            class="ml-4"
            style="text-transform: none;"
            :append-outer-icon="suggest ? 'mdi-table-search' : ''"
            :validate-on-blur="true"
            :auto-select-first="false"
            :open-on-clear="false"
            :menu-props="{closeOnContentClick:true, closeOnClick:true}"
            @click:append-outer="selector.show=!selector.show"
          />
          <!--
            @focus="selector.show = true"
            @click:append="selector.show = true"
            @keyup.tab="selector.show = false"
            @click.stop.prevent=""
            :filter="filterSearch"
            @keyup.native="teste"
            @keyup.enter="selector.show = false"
          -->
        </span>
        <v-text-field 
          v-if="maxOffset>1"
          v-model='offset'
          prefix="Bloco"
          type="number"
          class="mx-4"
          dense
          :hint="'de ' + maxOffset + ' (' + totalRecords + ')'"
          persistent-hint
          style="max-width: 80px;"
        />
        <div class="mx-2" />
        <v-spacer/>
        <slot
          name="btnGrid"
          :main="this"
        ></slot>

        <v-menu v-if="videos.length > 0 && !specialSetup" content-class="toolbar-menu" open-on-hover bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="mr-2" small icon @click="canalYoutube">
              <v-icon>mdi-youtube</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-subtitle class="pb-0">Canal Youtube</v-card-subtitle>
            <v-list dense>
              <v-list-item v-for="(item, idx) in videos" :key="idx" @click="selVideo(item)">
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

        <div v-if="summation">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="mr-2" small icon @click="execSoma">
                <v-icon>mdi-sigma</v-icon>
              </v-btn>
            </template>
            <span>Soma campos númericos</span>
          </v-tooltip>
        </div>

        <div v-if="isChild() && modified()" />
        <div v-else-if="modified()" class="scrolling-item">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="mr-2" small icon :loading="saving" @click.stop="save">
                <v-icon :color="valid() ? '' : 'red'">mdi-content-save</v-icon>
              </v-btn>
            </template>
            <span>Salvar</span>
          </v-tooltip>
        </div>
        <div v-if="showRefresh && (!isChild()) && (!specialSetup)" class="scrolling-item">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="mr-0" small icon @click.stop="cancel" :loading="searchLoading">
                <v-icon color="red">mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>{{ tooltipRefresh }}</span>
          </v-tooltip>
        </div>
        <div v-if="myCreatable" class="scrolling-item">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" 
                class="mr-0" small
                :color="captionAdd ? 'primary' : ''"
                :icon="captionAdd ? false : true"
                @click.stop="newItem()"
              >
                <v-icon>mdi-plus</v-icon>
                {{ captionAdd }}
              </v-btn>
            </template>
            <span>Novo Item</span>
          </v-tooltip>
        </div>
      </v-toolbar>
    </div>

    <!-- Botão ADD flutuante em Child -->
    <v-btn 
      v-else-if="showGrid && myCreatable && !editing"
      x-small fab right top absolute color="indigo"
      style="top: -4px;" @click.stop="newItem()">
      <v-icon small color="white">mdi-plus</v-icon>
    </v-btn>

    <slot
      name="topGrid"
      :main="this"
    ></slot>

    <!--
      Grid
    -->
    <v-data-table
      v-if="!editing"
      v-model="isSelected"
      :class="isChild() ? 'my-1 px-3 py-5' : 'elevation-2 mx-5 mb-2 px-3 py-1 my-data-table'"
      :headers="columns"
      :items="records"
      :no-data-text="showGrid ? 'Nenhum item encontrado' : ''"
      :loading="searchLoading"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.rowsPerPage"
      :sort-by.sync="pagination.sortBy"
      :sort-desc.sync="pagination.descending"
      :footer-props="pagination.options"
      :show-select="showSelect"
      :single-select="singleSelect"
      :show-expand="showExpand"
      :expanded.sync="isExpanded"
      :single-expand="singleExpand"
      loading-text="Carregando itens ..."
      dense
      mobile-breakpoint="1"
      :search="searchTable"
      :custom-filter="customFilter"
    >
      <template v-slot:top>
        <slot
          name="searchTable"
          :main="this"
        ></slot>
      </template>
      <template v-slot:item="props">
        <tr
          v-if="addSorted(props.item)"
          @click="canEdit(props.item)"
          :class="props.item === editedItem ? 'selected_row' : ''"
          :style="styleTR && styleTR(props.item)"
        >
          <td v-if="showExpand">
            <v-btn icon small @click="expandItem(props.item)">
              <v-icon v-if="isExpandedItem(props.item)">mdi-chevron-up</v-icon>
              <v-icon v-else>mdi-chevron-down</v-icon>
            </v-btn>
          </td>
          <td v-if="showSelect">
            <v-checkbox v-if="(!filterSelect) || filterSelect(props.item)" v-model="props.isSelected" 
              :disabled="disableSelect ? disableSelect(props.item) : false" @change="changeIsSelected(props)" />
          </td>
          <td v-for="item in columns" :key="item.value" :style="styleTD && styleTD(item)"
            :class="(item.align=='left'?'text-left':(item.align=='right'?'text-right':'justify-center layout px-0'))"
          >
            <template v-if="item.value=='actions'">
              <v-icon v-if="modifiedItem(props.item)" small class="mr-2" :color="validItem(props.item) ? '' : 'red'" :loading="saving" 
                @click.stop="isChild() ? msgNoSave() : saveItem(props.item)"
              >mdi-content-save</v-icon>
              <v-icon v-if="myEditable" small class="mr-2" @click.stop="editItem(props.item, false)">{{ myReadonly===true ? 'mdi-eye' : 'mdi-pencil' }} </v-icon>
              <v-icon v-if="myDeletable" small @click.stop="deleteItem(props.item, false)">mdi-delete</v-icon>
              <v-menu 
                v-if="flag"
                v-model="props.item.flagMenu"
                :close-on-content-click="false"
                offset-x
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    :small="!flagColor(props.item)"
                    :color="flagColor(props.item)"
                    v-on="on"
                    @click="flagClick(props.item)"
                  >
                    mdi-flag-variant
                  </v-icon>
                </template>
                <v-card class="pa-5" color="#fdf8b1">
                  <v-select v-model="flagId" label="Marcador" :items="flagItems" clearable />
                  <v-textarea v-model="flagText" label="Descrição" rows="5" />
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small color="primary" class="mr-4" @click="salvarFlag(props.item)">Salvar</v-btn>
                    <v-btn small @click="props.item.flagMenu=false">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </template>
            <template v-else-if="item.html">
              <div v-html="item.html(props.item[item.value], props.item, item, getCurrent())" />
            </template>
            <template v-else-if="item.component && (!item.component.hide || item.component.hide.indexOf(props.item) === -1)">
              <component
                v-bind:is="item.component.value"
                :value="props.item[item.value]"
                :type="item.component.type"
                :class="item.component.class"
                :color="item.component.color"
                :style="item.component.style"
                @change="(event) => { changeComponent(event, props.item, item.value) }"
                @click.prevent.stop="(event) => { clickComponent(event, item, props.item) }"
              >
                {{ item.component.text(props.item[item.value], item, props.item, getCurrent()) }}
              </component>
            </template>
            <template v-else-if="item.btn">
              <v-menu
                v-if="!item.btn.hide || item.btn.hide.indexOf(props.item) === -1"
                close-on-click
                close-on-content-click
                offset-y
              > 
                <template v-slot:activator="{ on }">
                  <v-btn
                    :text="item.btn.text !== false"
                    :small="item.btn.small !== false && !item.btn.xSmall"
                    :x-small="item.btn.xSmall"
                    :disabled="item.btn.disabled && item.btn.disabled(props.item, item, getCurrent())"
                    :depressed="item.btn.depressed"
                    :icon="item.btn.iconized"
                    :fab="item.btn.fab"
                    :outlined="item.btn.outlined"
                    :rounded="item.btn.rounded"
                    :tile="item.btn.tile"
                    :loading="item.loading || (item.btn.loading && item.btn.loading.indexOf(props.item) >= 0)"
                    :style="styleBtn(props.item, item, getCurrent())"
                    @click.stop="(event) => { item.btn.link && item.btn.link(event, props.item, item, getCurrent()) }"
                    v-on="on"
                    :color="item.btn.color"
                    :class="functionClass(props.item, item)"
                  >
                    <v-icon
                      v-if="item.btn.icon"
                      :small="item.btn.small !== false && !item.btn.xSmall"
                      :x-small="item.btn.xSmall"
                      class="mr-1"
                    >
                      {{ item.btn.icon }}
                    </v-icon>
                    <span class="my-font">{{ captionBtn(props.item, item, getCurrent()) }}</span>
                  </v-btn>
                </template>
                <v-list v-if="item.btn.items" dense>
                  <v-list-item
                    v-for="(menu, index) in item.btn.items"
                    :key="index"
                    :disabled="menu.disabled"
                    @click.stop="(event) => { menu.link && menu.link(event, props.item, item, getCurrent()) }"
                  >
                    <v-list-item-title>{{ menu.caption }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-else-if="item.calculate">
              <span v-html="item.calculate(props.item, item, getCurrent())"></span>
            </template>
            <template v-else-if="item.type == 'bool'">
              {{ props.item[item.value] | prettyBool }}
            </template>
            <template v-else>
              {{ props.item[item.value] | pretty(item, props.item) }}
            </template>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <slot
          name="expanded"
          :headers="headers"
          :item="item"
        ></slot>
      </template>
      <template v-slot:footer>
        {{ endSorted() }}
        <slot
          name="footerGrid"
          :main="this"
        ></slot>
      </template>
    </v-data-table>
    <!--
    <div
      v-if="showGrid && pagination.length > 1 && (!editing) && (!isChild())"
      class="my-pagination elevation-2"
    >
      <v-pagination
        v-model="pagination.page"
        :length="pagination.length"
      />
    </div>
    -->

    <div v-if="editing" class="mx-5">
      <v-toolbar
        :color="isDark() ? '#424242' : 'white'"
        dense
        :class="'scrolling-wrapper mt-5 mb-2 my-border ' + (isChild() ? 'elevation-0' : 'elevation-0')"
      >
        <v-toolbar-title v-if="singular" v-html="formTitle" class="scrolling-item"></v-toolbar-title>
        <!--<v-divider class="ml-2" vertical />-->
        <v-spacer/>
        <slot
          name="btnEdit"
          :main="this"
        ></slot>
        <!--
          Botões de edição
        -->
        <div v-if="replicate.length > 0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="mr-2" small icon @click="dReplicate=true">
                <v-icon>mdi-repeat</v-icon>
              </v-btn>
            </template>
            <span>Replicar campos</span>
          </v-tooltip>
        </div>
        <div v-if="showRefresh && !isChild() && (!newRec()) && (!specialSetup)" class="scrolling-item">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small icon class="mr-2" :loading="searchLoading" @click.stop="refreshItem">
                <v-icon color="red">mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Atualizar dados deste item (alterações serão descartadas)</span>
          </v-tooltip>
        </div>
        <div v-if="specialSetup || ((isChild() || hideSaveEdit) && modifiedIndex(editedIndex))" bottom />
        <div v-else-if="editedItem['__modified__']" class="scrolling-item">
          <v-menu content-class="toolbar-menu" open-on-hover bottom offset-y :disabled="!showAutoSave">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small icon class="mr-2" :loading="saving" @click.stop="saveIndex(editedIndex)">
                <v-icon :color="validItem(editedItem) ? '' : 'red'" >mdi-content-save</v-icon>
              </v-btn>
            </template>
            <v-tooltip bottom right>
              <template v-slot:activator="{ on }">
                <v-card 
                  class="px-4" 
                  height="32"
                  v-show="showGrid && (children.length === 0 || isChild())"
                  v-on="on"
                >
                  <v-switch v-model="autoSave" label="Auto salvar" />
                </v-card>
              </template>
              <span>Salva alterações ao fechar edição ou trocar de item</span>
            </v-tooltip>
          </v-menu>
        </div>
        <div v-if="isChild() && editedItem['__modified__'] && !newRec()" class="scrolling-item">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small icon class="mr-1" @click.stop="cancelIndex">
                <v-icon color="red">mdi-undo</v-icon>
              </v-btn>
            </template>
            <span>Desfazer alterações</span>
          </v-tooltip>
        </div>
        <div v-if="myDeletable && !specialSetup" class="scrolling-item">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small icon class="mr-1" @click.stop="deleteIndex(editedIndex, true)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Marcar item p/ ser Excluído</span>
          </v-tooltip>
        </div>
        <div v-if="myCreatable" class="scrolling-item">
          <v-menu v-if="canClone && !specialSetup" content-class="toolbar-menu" open-on-hover bottom offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small icon class="mr-1" @click.stop="newItem(null, autoSave)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-btn class="mt-3" dark @click.stop="cloneItem(autoSave)">
              <v-icon class="mr-2">mdi-content-duplicate</v-icon>
              {{ captionClone }}
            </v-btn>
          </v-menu>
          <v-tooltip v-else bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small icon class="mr-1" @click.stop="newItem(null, autoSave)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Novo item</span>
          </v-tooltip>
        </div>
        <div v-if="showGrid && (isChild() || (!newRec()) || specialSetup)" class="scrolling-item">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small icon @click.stop="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Fechar edição</span>
          </v-tooltip>
        </div>
        <!--
          Fim Botões de edição
        -->
      </v-toolbar>

      <slot
        name="topEdit"
        :main="this"
      ></slot>

      <v-card :class="isChild() ? 'mb-5' + classForm : ('mb-5 elevation-2 my-data-table ' + classForm)">
        <v-form v-model="validForm">
          <v-container grid-list-md class="py-0" style="max-width: 100%;">
            <v-tabs
              v-model="currentTab"
              v-show="!hideTabs"
              :height="((showTabs || tabs.length > 1) && !isEditingChild) ? 48 : 0"
              :class="((showTabs || tabs.length > 1) && !isEditingChild) ? 'elevation-2 my-tabs' : ''"
              background-color="#FF7F50"
              dark
            >
              <v-tab
                v-for="(tab, tabIdx) in tabs"
                :key="tabIdx"
                v-show="tab.visible"
                :disabled="tab.disabled"
              >
                <div v-if="(showTabs || tabs.length > 1) && !isEditingChild">
                  {{ tab.text }}
                </div>
              </v-tab>

              <slot
                name="tab"
                :main="this"
              ></slot>
            </v-tabs>

            <v-tabs-items 
              v-model="currentTab"
              :dark="isDark()"
            >
              <v-tab-item
                v-for="(tab, tabIdx) in tabs"
                :key="tabIdx"
                eager
              >
                <v-layout wrap class="mx-4 mt-4">
                  <v-flex
                    v-for="item of tab.edits"
                    :key="item.value"
                    :class="item.class + ' ' + item.class2"
                    @click.stop="(event) => onClick(event, item)"
                  >
                    <div 
                      v-if="item.group" 
                      class="my-group"
                      :style="item.groupStyle"
                    >
                      {{ item.group }}
                    </div>

                    <component 
                      v-if="item.component"
                      v-show="item.visible"
                      v-bind:is="item.component.value" 
                      :ref="item.value"
                      :class="item.component.class"
                      :color="item.component.color"
                      :style="item.component.style"
                      :disabled="myReadonly===true || item.readonly===true || item.disable===true"
                      @click="(event) => { clickComponent(event, item, editedItem[item.value]) }"
                    >
                      {{ item.component.text(editedItem[item.value], item, editedItem, getCurrent()) }}
                    </component>

                    <v-treeview
                      v-else-if="item.type=='treeview'"
                      v-show="item.visible"
                      v-model.trim="editedItem[item.value]"
                      :ref="item.value"
                      :name="item.value"
                      :label="item.text"
                      :items="item.items"
                      :disabled="myReadonly===true || item.readonly===true || item.disable===true"
                      dense
                      hoverable
                      selectable
                      item-disabled="locked"
                    ></v-treeview>

                    <v-combobox
                      v-else-if="item.combobox"
                      v-show="item.visible"
                      v-model.trim="editedItem[item.value]"
                      :ref="item.value"
                      :name="item.value"
                      :label="item.text"
                      :disabled="myReadonly===true || item.readonly===true || item.disable===true"
                      :hint="item.hint"
                      :error-messages="item.error"
                      :messages="modifiedKey(item.value)"
                      :rules="[validation(item)]"
                      :loading="item.loading"
                      :items="item.items"
                      :item-disabled="(i) => comboItemDisabled(i, item)"
                      :multiple="item.multiple"
                      :small-chips="item.multiple"
                      :deletable-chips="item.multiple"
                      :prefix="item.prefix"
                      :suffix="item.suffix"
                      :clearable="!item.required"
                      hide-no-data
                    ></v-combobox>

                    <div
                      v-else-if="item.items && item.counterItems"
                      v-show="item.visible"
                      @click="(event) => { toLinkDis(event, item) }"
                    >
                      <v-autocomplete
                        v-model.trim="editedItem[item.value]"
                        :ref="item.value"
                        :label="disabledStyle(item)"
                        :disabled="myReadonly===true || item.readonly===true || item.disable===true"
                        :hint="item.hint"
                        :error-messages="item.error"
                        :messages="modifiedKey(item.value)"
                        :rules="[validation(item)]"
                        :loading="item.loading"
                        :items="item.items"
                        :item-disabled="(i) => comboItemDisabled(i, item)"
                        :multiple="item.multiple"
                        :deletable-chips="item.multiple"
                        :prefix="item.prefix"
                        :suffix="item.suffix"
                        :clearable="!item.required"
                        :prepend-inner-icon="hasGrant(item)"
                        @click:prepend-inner="(event) => { toLink(event, item) }"
                        :append-outer-icon="item.itemsRefresh ? 'mdi-refresh' : item.appendOuterIcon"
                        @click:append-outer="itemsRefresh(item)"
                      >
                        <template v-slot:selection="dataItem">
                          <span v-if="dataItem.index===0" class="grey--text caption">
                            <b>{{ editedItem[item.value].length }}</b> selecionado<span v-if="editedItem[item.value].length > 1">s</span>
                          </span>
                        </template>
                      </v-autocomplete>
                    </div>

                    <div
                      v-else-if="item.items"
                      v-show="item.visible"
                      @click="(event) => { toLinkDis(event, item) }"
                    >
                      <v-autocomplete
                        v-model.trim="editedItem[item.value]"
                        :ref="item.value"
                        :name="item.value"
                        :label="disabledStyle(item)"
                        :disabled="myReadonly===true || item.readonly===true || item.disable===true"
                        :hint="item.hint"
                        :error-messages="item.error"
                        :messages="modifiedKey(item.value)"
                        :rules="[validation(item)]"
                        :loading="item.loading"
                        :items="item.items"
                        :item-disabled="(i) => comboItemDisabled(i, item)"
                        :multiple="item.multiple"
                        :deletable-chips="item.multiple"
                        :prefix="item.prefix"
                        :suffix="item.suffix"
                        :clearable="!item.required"
                        :prepend-inner-icon="hasGrant(item)"
                        @click:prepend-inner="(event) => { toLink(event, item) }"
                        :append-outer-icon="item.itemsRefresh ? 'mdi-refresh' : item.appendOuterIcon"
                        @click:append-outer="itemsRefresh(item)"
                        :small-chips="item.multiple"
                      ></v-autocomplete>
                    </div>

                    <my-field-join
                      v-else-if="item.join"
                      v-show="item.visible"
                      :ref="item.value"
                      :edited-item="editedItem"
                      :edited-key="item.value"
                      :label="item.text"
                      :disabled="myReadonly===true || item.readonly==true || item.disable===true"
                      :hint="item.hint"
                      :error-messages="item.error"
                      :messages="modifiedKey(item.value)"
                      :rules="[validation(item)]"
                      :loading="item.loading"
                      :join="item.join===true ? item.value : item.join"
                      :link="item.link"
                      :prefix="item.prefix"
                      :suffix="item.suffix"
                      :append-outer-icon="item.appendOuterIcon"
                      :append-outer-click="item.appendOuterClick"
                    ></my-field-join>

                    <v-text-field
                      v-else-if="item.readonly==false && (item.type=='date' || item.type=='time')"
                      v-show="item.visible"
                      v-model="editedItem[item.value]"
                      :ref="item.value"
                      :name="item.value"
                      :label="item.text"
                      :type="item.type"
                      :hint="item.hint"
                      :disabled="myReadonly===true || item.readonly===true || item.disable===true"
                      :error-messages="item.error"
                      :no-title="true"
                      :messages="modifiedKey(item.value)"
                      :rules="[validation(item)]"
                      :loading="item.loading"
                      :prefix="item.prefix"
                      :suffix="item.suffix"
                    ></v-text-field>

                    <my-datetime-picker
                      v-else-if="item.type=='datetime'"
                      v-show="item.visible"
                      v-model="editedItem[item.value]"
                      :ref="item.value"
                      :name="item.value"
                      :label="item.text"
                      :hint="item.hint"
                      :disabled="myReadonly===true || item.readonly===true || item.disable===true"
                      :error-messages="item.error"
                      :no-title="true"
                      :messages="modifiedKey(item.value)"
                      :rules="[validation(item)]"
                      :loading="item.loading"
                      date-format="dd/MM/yyyy"
                      clearText="Limpar"
                    >
                      <template slot="dateIcon">
                        <v-icon>mdi-calendar</v-icon>
                      </template>
                      <template slot="timeIcon">
                        <v-icon>mdi-clock</v-icon>
                      </template>
                    </my-datetime-picker>

                    <v-switch
                      v-else-if="item.type=='bool'"
                      v-show="item.visible"
                      v-model="editedItem[item.value]"
                      :ref="item.value"
                      :name="item.value"
                      :label="item.text"
                      :disabled="myReadonly===true || item.readonly==true || item.disable===true"
                      :hint="item.hint"
                      :error-messages="item.error"
                      :messages="modifiedKey(item.value)"
                      :rules="[validation(item)]"
                      :loading="item.loading"
                    ></v-switch>

                    <div v-else-if="item.type=='text'">
                      <v-tooltip
                        top
                        :disabled="!((myReadonly===true || item.readonly==true || item.disable===true) && !!editedItem[item.value])"
                      >
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            <v-textarea
                              v-show="item.visible"
                              v-model.trim="editedItem[item.value]"
                              :ref="item.value"
                              :name="item.value"
                              :label="item.text"
                              :disabled="myReadonly===true || item.readonly==true || item.disable===true"
                              :hint="item.hint"
                              :error-messages="item.error"
                              :rules="[validation(item)]"
                              :loading="item.loading"
                              :prefix="item.prefix"
                              :suffix="item.suffix"
                              :rows="item.rows"
                              :append-outer-icon="item.appendOuterIcon"
                              @click:append-outer="() => item.appendOuterClick(editedItem, item, self)"
                            ></v-textarea>
                          </div>
                        </template>
                        <span>{{ editedItem[item.value] }}</span>
                      </v-tooltip>
                    </div>

                    <div
                      v-else-if="item.type=='img'"
                      v-show="item.visible"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-image-input
                            v-model="editedItem[item.value]"
                            :ref="item.value"
                            :name="item.value"
                            :image-height="item.image.height"
                            :image-width="item.image.width"
                            :image-quality="item.image.quality"
                            :rules="[validation(item)]"
                            :loading="item.loading"
                            v-on="on" 
                            clearable
                            fullHeight
                            fullWidth
                            image-min-scaling="contain"
                          ></v-image-input>
                        </template>
                        <span> {{ item.hint || item.text }} </span>
                      </v-tooltip>
                      <span v-if="!editedItem[item.value]" class="img-input">
                        Clique para adicionar imagem
                      </span>
                    </div>

                    <my-upload-file
                      v-else-if="item.type=='upload'"
                      v-show="item.visible"
                      v-model.trim="editedItem[item.value]"
                      :ref="item.value"
                      :name="item.value"
                      :edited-item="editedItem"
                      :edited-key="item.value"
                      :label="item.text"
                      :disabled="myReadonly===true || item.readonly==true || item.disable===true"
                      :hint="item.hint"
                      :error-messages="item.error"
                      :messages="modifiedKey(item.value)"
                      :rules="[validation(item)]"
                      :loading="item.loading"
                    ></my-upload-file>

                    <v-text-field
                      v-else-if="item.mask"
                      v-show="item.visible"
                      v-model.trim="editedItem[item.value]"
                      v-mask="item.mask"
                      :ref="item.value"
                      :name="item.value"
                      :label="item.text"
                      :disabled="myReadonly===true || item.readonly==true || item.disable===true"
                      :hint="item.hint"
                      :error-messages="item.error"
                      :type="setType(item)"
                      :messages="modifiedKey(item.value)"
                      :rules="[validation(item)]"
                      :loading="item.loading"
                      :maxlength="item.length || 255"
                      :prefix="item.prefix"
                      :suffix="item.suffix"
                      :prepend-icon="item.prependIcon"
                      @blur="onBlur(item)"
                      @click:prepend="() => item.prependClick(editedItem)"
                      :prepend-inner-icon="item.prependInnerIcon"
                      @click:prepend-inner="() => item.prependInnerClick(editedItem)"
                      :append-icon="(item.type==='password' ? (item.hidePwd ? 'mdi-eye' : 'mdi-eye-off') : item.appendIcon)"
                      @click:append="function () {
                        if (item.type==='password') item.hidePwd = !item.hidePwd
                        else item.appendClick(editedItem)
                      }"
                      :append-outer-icon="item.appendOuterIcon"
                      @click:append-outer="() => item.appendOuterClick(editedItem, item, self)"
                      :class="item.type === 'int' || item.type === 'float' ? 'myNumber' : ''"
                    ></v-text-field>

                    <v-text-field
                      v-else-if="item.calculate"
                      v-show="item.visible"
                      :value="item.calculate(editedItem, item, getCurrent())"
                      :ref="item.value"
                      :name="item.value"
                      :label="item.text"
                      :disabled="true"
                      :hint="item.hint"
                      :error-messages="item.error"
                      :loading="item.loading"
                      :prefix="item.prefix"
                      :suffix="item.suffix"
                      :prepend-icon="item.prependIcon"
                      :prepend-inner-icon="item.prependInnerIcon"
                      @click:prepend-inner="() => item.prependInnerClick(editedItem)"
                      :append-icon="(item.type==='password' ? (item.hidePwd ? 'mdi-eye' : 'mdi-eye-off') : item.appendIcon)"
                      @click:append="function () {
                        if (item.type==='password') item.hidePwd = !item.hidePwd
                        else item.appendClick(editedItem)
                      }"
                      :append-outer-icon="item.appendOuterIcon"
                      @click:append-outer="() => item.appendOuterClick(editedItem, item, self)"
                      :class="item.type === 'int' || item.type === 'float' ? 'myNumber' : ''"
                    ></v-text-field>

                    <v-text-field
                      v-else
                      v-show="item.visible"
                      v-model.trim="editedItem[item.value]"
                      :ref="item.value"
                      :name="item.value"
                      :label="item.text"
                      :disabled="myReadonly===true || item.readonly==true || item.disable===true"
                      :hint="item.hint"
                      :error-messages="item.error"
                      :type="setType(item)"
                      :messages="modifiedKey(item.value)"
                      :rules="[validation(item)]"
                      :loading="item.loading"
                      :maxlength="item.length || 255"
                      :prefix="item.prefix"
                      :suffix="item.suffix"
                      :prepend-icon="item.prependIcon"
                      @blur="onBlur(item)"
                      @click:prepend="() => item.prependClick(editedItem)"
                      :prepend-inner-icon="item.prependInnerIcon"
                      @click:prepend-inner="() => item.prependInnerClick(editedItem)"
                      :append-icon="(item.type==='password' ? (item.hidePwd ? 'mdi-eye' : 'mdi-eye-off') : item.appendIcon)"
                      @click:append="function () {
                        if (item.type==='password') item.hidePwd = !item.hidePwd
                        else item.appendClick(editedItem)
                      }"
                      :append-outer-icon="item.appendOuterIcon"
                      @click:append-outer="() => item.appendOuterClick(editedItem, item, self)"
                      :class="item.type === 'int' || item.type === 'float' ? 'myNumber' : ''"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-tab-item>

              <slot
                name="tab-items"
                :main="this"
              ></slot>
            </v-tabs-items>

            <slot
                name="bodyEdit"
                :main="this"
            ></slot>

            <v-col v-if="navigable && !isEditingChild" class="pt-2">   <!-- class="hidden-sm-and-down"> -->
              <v-divider class="mb-3" />
              <v-row align="center" justify="center">
                <v-btn 
                  :disabled="pagination.page <= 1 && editedIndex == 0"
                  small icon color="indigo" 
                  @click.stop="incIndex(-2)"
                >
                  <v-icon>mdi-page-first</v-icon>
                </v-btn>
                <v-btn 
                  :disabled="pagination.page <= 1 && editedIndex == 0"
                  small icon color="indigo" 
                  @click.stop="incIndex(-1)"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span class="justify-center">
                  Item {{ ((pagination.page - 1) * pagination.rowsPerPage) + (editedIndex + 1) }}
                  de {{ records.length }}
                </span>
                <v-btn
                  :disabled="pagination.page >= pagination.length && editedIndex >= $options.sorted.length-1"
                  small icon color="indigo" 
                  @click.stop="incIndex(1)"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn
                  :disabled="pagination.page >= pagination.length && editedIndex >= $options.sorted.length-1"
                  small icon color="indigo"
                  @click.stop="incIndex(2)"
                >
                  <v-icon>mdi-page-last</v-icon>
                </v-btn>

                <!-- <v-divider vertical class="mx-2" />
                <span>
                  Página {{ pagination.page }} de {{ pagination.length }}
                </span>
                <v-btn :disabled="pagination.page <= 1 || !validItem(editedItem)" small icon color="indigo" @click.stop="moveToPage(-1)">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn :disabled="pagination.page >= pagination.length || !validItem(editedItem)" small icon color="indigo" @click.stop="moveToPage(1)">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn> -->

                <v-divider vertical class="mx-2" />
                <v-btn v-if="archive && canArchive() && (!specialSetup)" small text color="indigo" @click.stop="pathArchive">
                  <v-icon small class="mr-1">mdi-update</v-icon>
                  Histórico
                </v-btn>
              </v-row>
            </v-col>
          </v-container>
        </v-form>
      </v-card>

      <slot
        :main="this"
      ></slot>
    </div>

    <!--
      Anexos
    -->
    <v-card 
      v-if="(editing && myAttach && !newRec()) || (myAttachInGrid && !editing)"
      class="my-attach elevation-2"
    >
      <my-attach
        ref="myAttach"
        :name="name"
        :main="isChild() ? main : null"
        :attachview="myAttachview"
        :attachadd="myAttachadd && !myAttachInGrid"
        :attachdel="myAttachdel && !myAttachInGrid"
        :editedItem="editedItem"
      />
    </v-card>

    <!--
      Search
    -->
    <v-card v-if="suggest && selector && selector.show && !editing" class="my-selector">
      <v-container grid-list-md class="pt-4 pb-5 pl-8">
        <v-layout column>
          <v-flex>
            <v-layout wrap @click.stop.prevent="">
              <v-flex xs6 sm2><v-select v-model="selector.table" :items="selector.tables"/></v-flex>
              <v-flex xs6 sm2><v-select v-model="selector.field" :items="selector.fields"/></v-flex>
              <v-flex xs6 sm2><v-select v-model="selector.op" :items="selector.ops"/></v-flex>
              <v-flex xs6 sm2 v-if="selector.isDate"><v-select v-model="selector.function" :items="selector.functions"/></v-flex>
              <v-flex xs6 sm2>
                <v-switch
                  v-if="selector.isBool"
                  v-model="selector.value"
                ></v-switch>
                <v-text-field 
                  v-else
                  v-model.trim="selector.value"
                  :type="selector.isNumber ? 'number' : (selector.isDate ? 'date' :'text')"
                  :error-messages="selector.error"
                  placeholder="Digite aqui"
                  @keyup.enter.stop="addSearch"
                />
              </v-flex>
              <v-flex xs2 sm1><v-select v-model="selector.comp" :items="selector.comps"/></v-flex>
              <v-flex xs1><v-btn class="ml-4 mt-4" icon @click="addSearch"><v-icon large color="indigo">mdi-magnify-plus-outline</v-icon></v-btn></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
      <v-btn absolute icon style="right: 0px; top: 0px;"><v-icon color="red">mdi-close</v-icon></v-btn>
    </v-card>

    <!--
      Salvando
    -->
    <v-dialog v-model="saving" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="card-saving">
          <p class="pt-4">Salvando dados. Por favor, aguarde...</p>
          <v-progress-linear indeterminate color="white" class="mb-0"/>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--
      Soma campos numéricos
    -->
    <v-dialog v-model="somatoria" width="400">
      <v-card>
        <v-toolbar color="primary" dark :height="36">
          <v-toolbar-title>Soma</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="somatoria=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <table class="pa-4" style="width: 100%;">
          <tr v-for="key in somaKeys" :key="key" class="mytr-hover">
            <td class="text-left pr-4">{{ key }}</td>
            <td class="text-right">{{ formatFloat(somaVal[key]) }}</td>
          </tr>
        </table>
      </v-card>
    </v-dialog>

    <!--
      Video do Youtube
    -->
    <v-dialog v-model="showVideo" max-width="592px">
      <v-card class="px-4 pt-2 pb-4">
        <v-card-subtitle class="pa-0">{{ video.text }}</v-card-subtitle>
        <iframe
          width="560" height="315"
          :src="video.url"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
        </iframe>
      </v-card>
    </v-dialog>

    <!--
      Replicar campos
    -->
    <v-dialog v-model="dReplicate" width="800">
      <v-card>
        <v-toolbar color="primary" dark :height="36">
          <v-toolbar-title>Replicar campos</v-toolbar-title>
        </v-toolbar>
        <v-switch v-model="replicNull" label="Não alterar itens com valor" class="ml-8" />
        <v-data-table
          v-model="replicSelected"
          :headers="replicHeaders"
          :items="replicItems"
          :items-per-page="100"
          item-key="name"
          hide-default-footer
          mobile-breakpoint="1"
          dense
          show-select
          class="px-4 replicTD"
        />
        <v-card-actions class="pt-8 pb-6">
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mx-4" @click="replication">
            Replicar
          </v-btn>
          <v-btn @click="dReplicate=false">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import FieldJoinView from '@/components/base/FieldJoin.vue'
  import DatePickerView from '@/components/base/DatePicker.vue'
  import DatetimePicker from '@/components/base/DatetimePicker.vue'
  import AttachView from '@/components/base/Attach.vue'
  import ImageInput from 'vuetify-image-input'
  import UploadFileView from '@/components/base/UploadFile.vue'
  import ajax from '@/js/base/ajax'
  import { Field, fModifier, deepCopy, toFloat, toFixed, hasGrant, toLink, now, today, setCookie, findValue } from '@/js/base/misc'
  import { pretty, prettyDate, prettyDatetime, formatFloat } from '@/js/base/filters'
  import store from '@/store'
  import { mapState } from 'vuex'
  import { mask } from 'vue-the-mask'
  import { dateFormat } from '@/js/base/dateformat'
  // import { VMoney } from 'v-money'

  export default {
    directives: {
      mask: mask
      // money: VMoney
    },

    props: {
      name: { type: String, default: 'main' },
      main: { type: Object, default: null },
      styleTR: { type: Function, default: null },
      styleTD: { type: Function, default: null },
      current: { type: Object, default: null },
      caption: { type: String, default: 'CRUD' },
      singular: { type: String, default: 'Item' },
      readonly: { default: null },
      creatable: { type: Boolean, default: true },
      editable: { type: Boolean, default: true },
      deletable: { type: Boolean, default: true },
      searchable: { type: Boolean, default: true },
      remoteConfig: { type: Boolean, default: true },
      titlable: { type: Boolean, default: true },
      titleId: { type: Boolean, default: true },
      titleField: { type: String, default: 'nome' },
      showGrid: { type: Boolean, default: true },
      showUnique: { type: Boolean, default: null },
      showSelect: { type: Boolean, default: false },
      singleSelect: { type: Boolean, default: false },
      showExpand: { type: Boolean, default: false },
      singleExpand: { type: Boolean, default: false },
      showAutoSave: { type: Boolean, default: true },
      canClone: { type: Boolean, default: true },
      captionClone: { type: String, default: 'Duplicar' },
      captionAdd: { type: String, default: '' },
      filterSelect: { type: Function, default: null },
      disableSelect: { type: Function, default: null },
      navigable: { type: Boolean, default: true },
      attach: { type: Boolean, default: true },
      attachview: { type: Boolean, default: true },
      attachadd: { type: Boolean, default: true },
      attachdel: { type: Boolean, default: true },
      attachInGrid: { type: Boolean, default: false },
      width: { type: String, default: 'auto' },
      descending: { type: Boolean, default: false },
      rowsPerPage: { type: Number, default: (screen.height <= 800 ? 10 : 20) },
      sortBy: { type: String, default: 'id' },
      groupBy: { type: String, default: '' },
      parentId: { type: String, default: 'id' },
      suggest: { type: Boolean, default: true },
      placeHolder: { type: String, default: '' },
      archive: { type: Boolean, default: true },
      iconFavorite: { type: Boolean, default: true },
      showRefresh: { type: Boolean, default: true },
      classForm: { type: String, default: '' },
      tooltipRefresh: { type: String, default: 'Atualizar dados (alterações serão descartadas)' },
      hideSaveEdit: { type: Boolean, default: false },
      host: { type: String, default: null },
      videos: { type: Array, default: () => [] },
      summation: { type: Boolean, default: false },
      opacity: { type: Number, default: 1 },
      filterTable: { type: Function, default: null },
      replicate: { type: Array, default: () => [] },
      flag: { type: Boolean, default: false }
    },

    components: {
      'my-field-join': FieldJoinView,
      'my-date-picker': DatePickerView,
      'my-datetime-picker': DatetimePicker,
      'v-image-input': ImageInput,
      'my-upload-file': UploadFileView,
      'my-attach': AttachView
    },

    data: () => ({
      self: null,
      myCaption: null,
      forceUpdate: 0,
      pathname: '',
      myTabs: 1,
      editing: false,
      searchTable: '',
      searchItems: [],
      searchText: '',
      searchPermanent: '',
      searchLast: [],
      searchLoading: false,
      searchError: '',
      specialSetup: false,  // mostra/oculta botoes para edicao especial
      offset: 1,
      lastOffset: 1,
      totalRecords: 0,
      maxOffset: 0,
      isSelected: [],
      isExpanded: [],
      lastSelected: [],
      selector: {
        show: false,
        error: null,
        permanent: '',
        shortcut: '',
        shortcuts: [],
        table: '',
        lastTable: null,
        tables: [],
        columns: {},
        field: '',
        lastField: null,
        fields: [],
        isDate: false,
        isNumber: false,
        isBool: false,
        op: 'igual',
        ops: ['igual', 'diferente', 'contém', 'maior', 'maior ou igual', 'menor', 'menor ou igual', 'inicia', 'termina'],
        value: '',
        function: '',
        functions: ['', 'Ano', 'Mês', 'Dia', 'Hoje'],
        comp: 'E',
        comps: ['E', 'OU'],
        searchBy: ''
      },
      fields: {},
      populate: {},
      tabs: [],
      hideTabs: false,
      currentTab: 0,
      showTabs: false,
      columns: [],
      records: [],
      deleteds: [],
      editedIndex: -1,
      lastIndex: -1,
      editedItem: {},
      saving: false,
      validForm: false,
      filename: '',
      remoteReadonly: false,
      remoteCreatable: true,
      remoteEditable: true,
      remoteDeletable: true,
      remoteSearchable: true,
      remoteAttach: true,
      remoteAttachview: true,
      remoteAttachadd: true,
      remoteAttachdel: true,
      fieldsSelect: [],
      children: [],
      autoSave: true,
      saveParams: null,
      linkParams: null,
      isFavorite: false,
      isEditingChild: false,
      rePage: -1, // usado p/ mudar de pagina no modo de edicao
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        sortBy: '',
        totalItems: 0,
        length: 0,
        options: {
          showFirstLastPage: true,
          itemsPerPageText: 'Itens por página',
          itemsPerPageOptions: [10, 20, 50, 100, -1],
          itemsPerPageAllText: 'Todos'
        },
      },
      somatoria: false,
      somaKeys: [],
      somaVal: {},
      showVideo: false,
      video: 0,
      dReplicate: false,
      replicNull: true,
      replicSelected: [],
      replicHeaders: [
        { text: 'Campo', value: 'description', align: 'left', sortable: false },
        { text: 'Valor', value: 'text', align: 'left', sortable: false }
      ],
      replicItems: [],
      flagItems: [],
      flagId: null,
      flagText: ''
    }),

    sorted: [],
    beginSort: true,
    debounceLast: {}, // usado chamadas ajax dentro de rules, variavel não reativa
    debounceList: {},

    computed: {
      ...mapState(
        ['joinLink', 'favorites', 'forceRefresh']
      ),
      formTitle () {
        let result = '<span style="font-size: 1.0rem;"><b>' + this.singular
        let value = ''
        if (this.newRec())
          value += ' (NOVO)'
        else if (this.titleId)
          value += (' ' + this.editedItem['id']) || ''
        if (this.editedItem[this.titleField])
          value += ': </b>' + this.editedItem[this.titleField]
        else
          value += '</b>'
        let field = this.fields[this.titleField]
        if (!field)
          field = this.fields['id']
        if (field)
          result += value
        result += '</span>'
        // if (this.navigable) {
        //   let pos = ((this.pagination.page - 1) * this.pagination.rowsPerPage) + (this.editedIndex + 1)
        //   result += '<span style="font-size: 50%">&nbsp;&nbsp;('
        //   result += String(pos) + ' de ' + String(this.records.length) + ') '
        //   result += '</span>'
        // }
        return result
      },
      myReadonly () {
        let result = this.readonly || this.remoteReadonly
        if (!result && this.main)
          result = this.main.myReadonly
        return result
      },
      myReadonlyAttach () {
        return this.readonly === null ? false : this.readonly
      },
      myCreatable () { 
          return this.creatable && this.remoteCreatable && ((!this.isChild()) || (!this.myReadonly))
      },
      myEditable () { 
        return this.editable && this.remoteEditable
      },
      myDeletable () { 
        return !this.myReadonly && (this.newRec() || (this.deletable && this.remoteDeletable))
      },
      mySearchable () {
        return this.searchable && this.remoteSearchable
      },
      myAttach () {
        return this.attach && this.remoteAttach && this.editedIndex >= 0 && (this.myAttachview || this.myAttachadd || this.myAttachdel)
      },
      myAttachview () {
        return this.attachview && this.remoteAttachview && this.editedIndex >= 0
      },
      myAttachadd () { 
        return !this.myReadonlyAttach && this.attachadd && this.remoteAttachadd && this.editedIndex >= 0
      },
      myAttachdel () {
        return !this.myReadonlyAttach && this.attachdel && this.remoteAttachdel && this.editedIndex >= 0
      },
      myAttachInGrid () {
        return this.attachInGrid && this.editedIndex >= 0
      },
      myShowUnique () {
        return this.showUnique === null ? !this.isChild() : this.showUnique
      },
      recordsSorted () {
        return this.$options.sorted
      }
    },

    watch: {
      forceRefresh () {
        this.configFav()
        this.$emit('refresh', this)
      },
      editedItem: {
        handler: function (item) {
          for (var key in item) {
            let field = this.fields[key]
            if (field && field.readonly && (!field.items) && item[key]) {
              // usado especialmente para formatar date e datetime
              this.editedItem[key] = pretty(item[key], field, item, false)
            } else if (field && field.type === 'float' && !field.fixDecimal) {
              this.editedItem[key] = parseFloat(item[key]) // retirar zeros da direita do ponto
            }
          }
        },
        deep: false
      },
      editing (val) {
        this.myTabs = 1
        if (this.main) {
          this.main.isEditingChild = false //val
        }
        val || this.close()
        !val || this.$emit('editing', this)
      },
      isEditingChild (val) {
        if (!val) {
          for (let child of this.children) {
            if (child.editing) {
              this.isEditingChild = true
              break
            }
          }
        }
      },
      searchText () {
        this.offset = 1
        this.searchRefresh()
        if (this.$refs.comboboxSearch)
          this.$refs.comboboxSearch.isMenuActive = false
      },
      validForm (newval, oldval) {
        if (newval !== oldval) {
          if (this.editedIndex >= 0) {
            this.editedItem['__valid__'] = newval
          }
        }
      },
      selector: {
        handler: function (item) {
          if (item) {
            let type
            if (item.lastTable !== item.table) {
              item.lastTable = item.table
              item.fields = []
              for (let key in item.columns[item.table])
                item.fields.push(key)
              item.field = item.fields[0]
              item.lastField = null
            }

            if (item.lastField !== item.field) {
              item.lastField = item.field
              type = item.columns[item.table][item.field].type
              item.isNumber = type === 'int' || type === 'float'
              item.isBool = type === 'bool'
              item.isDate = type === 'date' || type === 'datetime'
            } else if (item.table && item.field) {
              type = item.columns[item.table][item.field].type
            } else {
              type = 'text'
            }

            if (type === 'date' || type === 'datetime') {
              item.isNumber = item.function !== ''
            }

            if (item.shortcut && (this.searchText.indexOf(item.shortcut) < 0)) {
              this.searchLast = []
              this.searchText.push(item.shortcut)
              item.shortcut = ''
              this.selector.show = false
            }
          }
        },
        deep: true
      },
      joinLink (val) {
        // FieldJoin requisitou abrir desta pagina, posicione no ID correto
        // poderia ser em activated() contudo deixou de funcionar quando Pedido é Faturado
        let link = val.split('?')[0]
        if (link && this.pathname.endsWith(link)) {
          this.$store.commit('JOIN_LINK', '')
          let params = decodeURI(window.location.search.substr(1))
          // params = params.replace(/%20/g, ' ').replace(/%22/g, '"').replace(/%3D/g, '=').replace(/%7C/g, '|')

          if (this.modified()) {
            document.body.setAttribute('style', 'cursor: default;')
            this.alert('Não foi possível posicionar no item correto. Antes [SALVE] o formulário.')
          } else if (params == '-.id=null') {
            if (this.myCreatable) {
              this.newItem()
            }
          } else {
            this.search(params)
          }
        }
      },
      isSelected: {
        handler: function () {
          for (let item of this.$options.sorted) {
            let current = this.isSelected.indexOf(item)
            let last = this.lastSelected.indexOf(item)
            if (current !== last) {
              if (current >= 0 && last === -1)
                this.$emit('changeSelected', true, item, this.fields, this) // (val, item, field, vm)
              else if (last >= 0 && current === -1)
                this.$emit('changeSelected', false, item, this.fields, this)
            }
          }
          this.lastSelected = this.isSelected
        },
        deep: true
      },
      favorites () {
        this.configFav()
      },
      offset (val) {
        var that = this
        val = parseInt(val)
        if (val < 1)
          setTimeout(function() { that.offset = 1 }, 10)
        else if (val > this.maxOffset)
          setTimeout(function() { that.offset = that.maxOffset }, 10)

        setTimeout(function() { 
          if (that.offset != that.lastOffset)
            that.cancel()
        }, 30)
      },
      showVideo (val) {
        if (!val) {
          this.stopVideo()
        }
      },

      dReplicate (val) {
        if (val) {
          let first = false
          if (this.replicItems.length == 0) {
            first = true
          } else {
            this.replicItems = []
          }
          let item = this.editedItem
          for (let field of this.replicate) {
            let value = item[field]
            let items = this.fields[field].items
            if (item[field + '__text']) {
              value += ' | ' + item[field + '__text']
            }
            else if (items && items.length > 0) {
              value = findValue(items, item[field])
            }
            let newitem = {
              name: field,
              description: this.fields[field].text,
              text: value
            }
            this.replicItems.push(newitem)
            if (first && value) {
              this.replicSelected.push(newitem)
            }
          }
        }
      }
    },

    created () {
      this.self = this
      var vm = this
      this.searchLoading = true
      this.pathname = window.location.pathname
      this.$store.commit('JOIN_LINK', '')

      if (this.main) {
        let found = false
        let children = this.main.children
        for (let x in children) {
          if (children[x].name === this.name) {
            found = true
            children[x] = this
            break
          }
        }
        if (!found) {
          children.push(this)
        }
      }

      this.pagination.rowsPerPage = this.rowsPerPage

      ajax.request('crud.php', { name: this.name, service: 'fields' },
        response => {
          if (response.data.fields != null) {
            let tablename = response.data.fields[0].tablename
            for (let item of response.data.fields) {
              let field2 = Field(
                item.name,
                {
                  type: item.type,
                  text: item.label,
                  length: item.length,
                  decimal: item.decimal,
                  required: !item.nullable,
                  default: item.default,
                  hint: item.hint,
                  readonly: item.readonly
                }
              )
              if (item.tablename === tablename) {
                vm.fields[item.name] = field2
              }
              if (vm.selector.tables.indexOf(item.tablename) < 0) {
                vm.selector.tables.push(item.tablename)
                vm.selector.columns[item.tablename] = {}
              }
              vm.selector.columns[item.tablename][item.name] = field2
            }
            let searchVal = response.data.search[0] || ''
            if (searchVal.endsWith('.id=null') || searchVal.endsWith('.id=')) {
              vm.$nextTick( () => {
                if (vm.myCreatable) {
                  vm.newItem()
                }
              })
            }
            vm.searchLast = response.data.search
            vm.searchText = vm.searchLast
        }
          if (response.data.favorites) {
            vm.selector.shortcuts = response.data.favorites
            vm.searchItems = response.data.favorites
          }
          if (response.data.permanent)
            vm.selector.permanent = response.data.permanent
          if (response.data.searchBy)
            vm.selector.searchBy = response.data.searchBy
          vm.selector.table = vm.selector.tables[0]
          let fields = []
          let sorted = []
          let columns = []
          let current = vm.current || vm.$parent
          let tabsTemp
          if (current[vm.name]) {
            fields = current[vm.name].fields
            sorted = current[vm.name].sorted
            columns = current[vm.name].columns || []
            tabsTemp = current[vm.name].tabs || {}
          }
          if (sorted === undefined) {
            sorted = []
          }

          this.execEvent('onMount', fields, sorted, columns, tabsTemp)

          let beforeList = []
          let afterList = []
          for (let x in fields) {
            if (vm.fields[x]) {
              fModifier(vm.fields[x], fields[x])
            } else {
              vm.fields[x] = Field(x, fields[x])
            }
            if (vm.fields[x]['items'] && vm.fields[x]['multiple']) {
              vm.fieldsSelect.push(x)
            }
            if (vm.fields[x].before) {
              beforeList.push(vm.fields[x])
            } else if (vm.fields[x].after) {
              afterList.push(vm.fields[x])
            }
          }
          vm.columns.push({ text: '', value: 'actions', align: 'left', sortable: false })
          let sortedList = []
          let tmpList
          if (sorted === undefined || sorted.length === 0 || sorted === false) {
            tmpList = vm.fields
          } else if (sorted === true) {
            tmpList = fields
          } else {
            sortedList = sorted
          }
          if (tmpList) {
            for (let x in tmpList) {
              sortedList.push(x)
            }
            for (let x in beforeList) {
              let field = beforeList[x]
              let inc = sortedList.indexOf(field.before)
              let del = sortedList.indexOf(field.value)
              if (inc >= 0) {
                if (del > inc) {
                  sortedList.splice(del, 1)
                  sortedList.splice(inc, 0, field.value)
                } else {
                  sortedList.splice(inc, 0, field.value)
                  sortedList.splice(del, 1)
                }
              } else {
                vm.error('Field.before inválido em [' + field.value + ']')
              }
            }
            for (let x in afterList) {
              let field = afterList[x]
              let inc = sortedList.indexOf(field.after)
              let del = sortedList.indexOf(field.value)
              if (inc >= 0) {
                if (del > inc) {
                  sortedList.splice(del, 1)
                  sortedList.splice(inc+1, 0, field.value)
                } else {
                  sortedList.splice(inc+1, 0, field.value)
                  sortedList.splice(del, 1)
                }
              } else {
                vm.error('Field.after inválido em [' + field.value + ']')
              }
            }
          }
          let tabs = []
          for (let x in tabsTemp)
            tabs.push({ text: tabsTemp[x], col: x, disabled: false, visible: true })
          if (tabs.length === 0)
            tabs.push({ text: 'Principal', col: '', disabled: false, visible: true })
          vm.tabs.push({ text: tabs[0].text, edits: [], disabled: false, visible: true })
          let nextBreak = tabs.length >= 2 ? tabs[1].col : null
          for (let x in sortedList) {
            let field = vm.fields[sortedList[x]]
            if (field.gridable && columns.length === 0)
              vm.columns.push(field)
            if (field.editable) {
              if (field.value === nextBreak) {
                nextBreak = tabs.length > (vm.tabs.length + 1) ? tabs[vm.tabs.length + 1].col : null
                vm.tabs.push({ text: tabs[vm.tabs.length].text, edits: [], disabled: false, visible: true })
              }
              vm.tabs[vm.tabs.length - 1].edits.push(field)
            }
          }
          for (let x of columns) {
            let field = vm.fields[x]
            if (!field)
              vm.error('Coluna [' + x + '] nao existe!')
            else if (field.gridable)
              vm.columns.push(field)
          }
          if (response.data.populate) {
            for (let i in response.data.populate) {
              let items = []
              for (let pop of response.data.populate[i]) {
                let popValues = { value: pop.id, text: pop.nome }
                if (pop.extra)
                  popValues['extra'] = pop.extra.split('^&')
                items.push(popValues)
              }
              vm.populate[i] = items
              if (vm.fields[i]) {
                vm.fields[i].items = items
                vm.fields[i].itemsRefresh = true
              }
            }
            this.flagItems = vm.populate.flag_id
          }
          if (response.data.grant != null && vm.remoteConfig) {
            vm.remoteCreatable = response.data.grant.insert === true
            vm.remoteEditable = true 
            vm.remoteReadonly = response.data.grant.update !== true
            vm.remoteDeletable = response.data.grant.delete === true
            vm.remoteSearchable = response.data.grant.search === true
            vm.remoteAttachview = response.data.grant.attachView === true
            vm.remoteAttachadd = response.data.grant.attachInsert === true
            vm.remoteAttachdel = response.data.grant.attachDelete === true
            vm.remoteAttach = vm.remoteAttachview || vm.remoteAttachadd || vm.remoteAttachdel
          }
          if (!vm.main) {
            vm.searchLoading = false
          } else {
            vm.searchLoading = !vm.main.newRec()
          }
          vm.loadRecords(response.data)
          for (let x of Object.keys(vm.fields)) {
            let field = vm.fields[x]
            if (field.visible) {
              // if ((field.type == 'int' && !(field.value=='id' || field.value.endsWith('_id'))) || field.type == 'float')
              if (field.type == 'float')
                vm.somaVal[field.value] = 0
            }
          }
          vm.somaKeys = Object.keys(vm.somaVal).sort()
          vm.execEvent('onAfterMount', vm.fields, vm.tabs)
        },
        null,
        () => {
          document.body.setAttribute('style', 'cursor: default;')
        },
        this.host,
        true
      )
      this.configFav()
    },

    methods: {
      onClick (event, item, id=null) {
        if (item.link && (item.readonly || item.disable))
          this.toLink(event, item, id)
      },

      formatFloat (val) {
        return formatFloat(val)
      },

      canalYoutube () {
        window.open(store.state.youtube, '_blank')
      },

      selVideo (item) {
        this.video = item
        this.showVideo = true
      },

      stopVideo () {
        let iframe = document.getElementsByTagName('iframe')
        for (let i = 0; i < iframe.length; i++) {
          var iframeSrc = iframe[i].src
          iframe[i].src = iframeSrc
        }
      },

      search (params) {
        this.editing = false
        this.records.splice(0, this.records.length)
        this.searchLast = []
        this.searchText.splice(0, this.searchText.length, params)
      },

      seek (id) {
        this.search('-.=' + id)
      },

      findId (id) {
        for (let item of this.records) {
          if (item.id === id)
            return item
        }
        return null
      },

      setType (item) {
        let result
        if (this.myReadonly || item.readonly || item.disable)
          result = 'text'
        else if (item.type === 'int' || item.type === 'float')
          result = 'number'
        else if (item.type === 'password' && item.hidePwd)
          result = 'password'
        else
          result = 'text'
        return result
      },

      addSorted (item) {
        if (this.$options.beginSort) {
          this.$options.beginSort = false
          this.$options.sorted = []
        }
        this.$options.sorted.push(item)
        return true
      },

      endSorted () {
        this.$options.beginSort = true
        this.calcPagination(this.records.length)
        if (this.rePage != -1) {
          this.editIndex(this.rePage)
          this.rePage = -1
        }
        return ''
      },

      calcPagination (length) {
        let result = 0
        if (this.pagination.rowsPerPage > 0) {
          result = parseInt((length || 0) / this.pagination.rowsPerPage)
          if (((length || 0) % this.pagination.rowsPerPage) !== 0)
            result += 1
        }
        if (result !== this.pagination.length)
          this.pagination.length = result || 1
      },

      alert (msg) {
        this.$store.commit('ADD_ALERT', msg)
      },

      error (msg, caption = null) {
        if (caption)
          this.$store.commit('CAPTION_ERROR', caption)
        this.$store.commit('ADD_ERROR', msg)
      },

      loadRecords (data) {
        let records = data.records || []
        if (data.totalRecords) {
          this.totalRecords = data.totalRecords
          this.maxOffset = parseInt(this.totalRecords / data.limitRecords) + 1
          if (this.offset > this.maxOffset) {
            this.offset = 1
          }
        }
        if (records) {
          this.editedIndex = -1
          this.editedItem = {}
          this.deleteds = []
          this.records = records
          for (let recno in this.records) {
            let record = this.records[recno]
            this.formatSelectIn(record)
            record.__original__ = deepCopy(record, ['__signature__', '__file', '__url'])
            this.$set(record, '__modified__', false)
            for (let col in record) {
              let val = record[col]
              let field = this.fields[col]
              if (field) {
                if (field.type == 'bool') {
                  val = (!val || val == '0') ? false : true
                  record[col] = val
                }
                else if (field.type == 'datetime' && val) {
                  val = new Date(val)
                  record[col] = val
                }
                else if (field.type == 'treeview') {
                  record[col] = (val || '').split('|')
                }
              }
            }
          }
          this.calcPagination(records.length)
          // if (records.length === 500)
            // this.alert('Pesquisa retornou muitos "' + this.caption + '" ! Somente 500 itens foram carregados.')
          if ((records.length === 1 && this.myShowUnique && this.editable && !this.specailSetup) || !this.showGrid)
            this.$nextTick(() => { this.editIndex(0) })
        } else {
          this.pagination.length = 0
        }
        if (this.isSelected.length > 0) {
          this.isSelected.splice(0, this.isSelected.length)
        }
        this.$emit('loaded', this, data)
      },

      formatSelectIn (record) {
        for (let key of this.fieldsSelect) {
          let value = record[key]
          record[key] = value ? value.split('|') : []
        }
      },

      formatSelectOut (value) {
        return (value || []).join('|')
      },

      linkToParent (parentId) {
        let vm = this
        if (parentId && parentId != 0) { // && this.searchLast !== parentId) {
          this.searchLoading = true
          // this.searchLast = parentId
          vm.loadRecords({})
          if (this.execEvent('onBeforeLink', vm.editedItem, vm.editIndex)) {
            return true
          }
          ajax.request('crud.php', { 'name': this.name, 'service': 'link', 'parentId': parentId, 'params': this.linkParams },
            response => {
              vm.loadRecords(response.data)
              vm.searchLoading = false
            },
            null,
            null,
            this.host,
            true
          )
        }
      },

      searchRefresh () {
        if (this.isChild()) {
          if (this.main.editedItem)
            this.linkToParent(this.main.editedItem[this.parentId])
          return
        }

        let vm = this
        if (this.searchPermanent && (this.searchText.indexOf(this.searchPermanent) < 0)) {
          this.searchText.splice(0, 0, this.searchPermanent)
        }

        if (this.searchLast !== this.searchText) {
          this.$emit('searching', this)
          this.searchLast = this.searchText
          if (this.searchText.length > 0 || !this.searchable) {
            document.body.setAttribute('style', 'cursor: wait;')
            this.searchLoading = true
            this.lastOffset = this.offset
            ajax.request('crud.php', { name: this.name, service: 'search', searchText: this.searchText, offset: this.offset-1 },
              response => {
                if (response.data.search && response.data.search[0]) {
                  vm.searchLast = response.data.search
                  vm.searchText = vm.searchLast
                }
                vm.searchError = response.data.searchError ? 'Pesquisa não pode ser interpretada corretamente' : null
                vm.loadRecords(response.data)
              },
              null,
              () => {
                vm.searchLoading = false
                document.body.setAttribute('style', 'cursor: default;')
              },
              this.host,
              true
            )
          } else {
            vm.searchError = 'Preencha pesquisa ou selecione um atalho'
            // vm.alert('Preencha pesquisa ou selecione um atalho')
          }
        }
      },

      newItem (values = null, save=false, forceEdit=false) {
        this.lastIndex = this.editedIndex
        if (this.execEvent('onPrepareCreate', {})) {
          return true
        }
        let curModified = this.modifiedItem(this.editedItem)
        if (curModified && !this.validItem(this.editedItem)) {
          this.showErrors()
          return true
        }
        let isModified = false
        let record = {}
        let records = this.records[this.records.length - 1]
        for (let x in this.fields) {
          let field = this.fields[x]
          let value = null
          if (x === 'id')
            value = 0
          if (field.type === 'bool')
            value = field.default === true || field.default === '1'
          else if (field.default !== null && field.default !== 'CURRENT_TIMESTAMP') {
            if (field.default === 'NOW') {
              if (field.type == 'time')
                value = today(true)
              else if (field.type == 'date')
                value = today()
              else
                value = now()
            } else
              value = field.default
          } else if (field.join) {
            value = ''
          } else if (field.type == 'img' && this.$refs[x]) {
            this.$refs[x][0].clear()
          }
          record[field.value] = value
        }
        if (records) {
          for (let i in records) {
            if (i !== '__original__' && records[i] !== record[i]) {
              isModified = true
              break
            }
          }
        } else {
          isModified = true
        }
        if (values) {
          isModified = true
          for (let key in values) {
            if (key !== 'id')
              this.$set(record, key, values[key])
          }
        }
        if (isModified) {
          if (this.execEvent('onBeforeCreate'))
            return true
          record.__original__ = deepCopy(record, ['__signature__', '__file', '__url'])
          this.$set(record, '__modified__', true)
          this.$set(record, '__valid__', true)
          this.records.splice(this.records.length, 0, record)
          this.$options.sorted.push(record)
          if (this.isChild())
            this.main.editedItem.__modified__ = true
          for (let child of this.children)
            child.loadRecords({})
        }
        let index = this.$options.sorted.length - 1
        if (forceEdit || !values)
          this.editIndex(index, save && curModified)
        this.currentTab = 0
        if (this.execEvent('onAfterCreate', this.$options.sorted[index], index))
          return true
      },

      cloneItem (autosave) {
        let curModified = this.modifiedItem(this.editedItem)
        if (curModified && !this.validItem(this.editedItem)) {
          this.showErrors()
          return true
        }
        let lastItem = this.editedItem
        let children = []
        for (let child of this.children) {
          children.push({ object: child, name: child.name, records: child.records })
        }
        this.newItem(this.editedItem, autosave, true)
        this.execEvent('onClone', this.editedItem, lastItem, children)
      },

      getRecno (item = null) {
        if (!item) 
          item = this.editedItem
        return this.$options.sorted.indexOf(item)
      },

      editItem (item, save = true) {
        if (this.searchLoading) {
          this.alert('Aguarde itens serem carregados ...')
          return true
        } else {
          return this.editIndex(this.getRecno(item), save)
        }
      },

      editIndex (index, save = true, force = false) {
        // return false = falha salvar
        // return true = cancelar evento NAV
        if (index < 0) {
          index = 0
        }
        else if (index >= this.$options.sorted.length) {
          index = this.$options.sorted.length - 1
        }

        if (index >= 0 && index < this.$options.sorted.length) {
          if (force || index !== this.editedIndex) {
            if (save && this.editedItem && this.autoSave && (!this.isChild()) && this.modified(this.editedItem)) {
              if (!this.saveItem(this.editedItem)) {
                return false
              }
            }
            document.body.setAttribute('style', 'cursor: wait;')
            if (this.execEvent('onBeforeNav', this.$options.sorted[index], index)) {
              return true
            }
            this.editedIndex = index
            this.editedItem = this.$options.sorted[this.editedIndex]
            for (var x in this.editedItem) {
              let field = this.fields[x]
              if (field && (field.type == 'img')) {
                if ((!this.editedItem[x]) && this.$refs[x]) {
                  if (field.type == 'img') {
                    this.$refs[x][0].clear()
                  }
                }
              }
            }
            for (let child of this.children) {
              child.close()
              child.linkToParent(this.editedItem[child.parentId])
            }
            setTimeout(function () { 
              document.body.setAttribute('style', 'cursor: default;') 
            }, 1000)
            if (this.execEvent('onAfterNav', this.$options.sorted[index], index)) {
              return true
            }
          }
          // this.isSelected.splice(0, this.isSelected.length)
          this.editing = true

          return true
        }
        return false
      },

      deleteItem (item, move) {
        this.deleteIndex(this.getRecno(item), move)
      },

      async deleteIndex (index, move) {
        if (this.execEvent('onBeforeDelete', this.$options.sorted[index], index)) {
          return true
        }
        // if (await this.$confirm('<BR>Você deseja marcar este item para ser excluído?')) {
          let item = this.$options.sorted[index]
          let index2 = this.records.indexOf(item)
          let newRec = this.newRec()
          if (!newRec) {
            this.deleteds.push(deepCopy(this.$options.sorted[index]))
          }
          this.$options.sorted.splice(index, 1)
          this.records.splice(index2, 1)
          if (index >= this.$options.sorted.length) {
            index = this.$options.sorted.length - 1
          }
          if (move) {
            if (this.lastIndex >= 0 && this.lastIndex < this.$options.sorted.length) {
              this.editIndex(this.lastIndex, false, true)
              this.lastIndex = -1
            } else if (index >= 0 && index < this.$options.sorted.length) {
              this.editIndex(index, false, true)
            } else {
              this.editedIndex = -1
              this.editedItem = {}
              this.editing = false
            }
          }
          if (!newRec) {
            this.alert('Para efetivar as exclusões SAVE o formulário.')
          }
          if (this.execEvent('onAfterDelete', this.$options.sorted[index], index)) {
            return true
          }
        // }
      },

      deleteAll () {
        if (this.execEvent('onBeforeDelete'))
          return true
        for (let record of this.records) {
          if (record.id > 0)
            this.deleteds.push(deepCopy(record))
        }
        this.$options.sorted.splice(0, this.$options.sorted.length)
        this.records.splice(0, this.records.length)
        this.editedIndex = -1
        this.editedItem = {}
        if (this.execEvent('onAfterDelete')) return true
      },

      async cancelIndex () {
        if (await this.$confirm('<BR>Desfazer as alterações deste item ?')) {
          if (this.newRec() && !this.deletable) {
            this.$options.sorted.splice(this.editedIndex, 1)
            this.editIndex(this.editedIndex - 1)
          } else {
            // let item = this.$options.sorted[this.editedIndex]
            // for (let i in item.__original__)
              // item[i] = item.__original__[i]
            for (let i in this.editedItem.__original__)
              this.editedItem[i] = this.editedItem.__original__[i]
          }
          for (let child of this.children) {
            child.close()
            child.linkToParent(this.editedItem[child.parentId])
          }
        }
      },

      clearall () {
        this.records.splice(0, this.records.length)
        this.$options.sorted.splice(0, this.$options.sorted.length)
        this.editedIndex = -1
        this.editedItem = {}
        this.searchLast = []
      },

      async cancel () {
        if ((!this.modified()) || await this.$confirm('<BR>As alterações NÃO serão salvas. Deseja continuar?')) {
          this.$options.sorted.splice(0, this.$options.sorted.length)
          this.editedIndex = -1
          this.editedItem = {}
          this.searchLast = []
          this.searchRefresh()
        }
      },

      async refreshItem () {
        if ((!this.modifiedItem(this.editedItem)) ||
        await this.$confirm('<BR>As alterações NÃO serão salvas. Deseja atualizar o item mesmo assim?')) {
          document.body.setAttribute('style', 'cursor: wait;')
          var that = this
          var recno = this.getRecno()
          that.searchLoading = true

          if (this.execEvent('onBeforeNav', this.editedItem, this.editedIndex)) {
            return true
          }

          ajax.request(
            'crud.php',
            { 
              name: this.name,
              service: 'refreshItem',
              id: this.editedItem.id,
              signature: this.editedItem.__signature__
            },
            response => {
              that.loadRecord(response.data.records[0], recno, response.data)
              that.execEvent('onAfterNav', that.editedItem, recno)
            },
            null,
            () => {
              that.searchLoading = false
              document.body.setAttribute('style', 'cursor: default;')
            },
            this.host,
            true
          )
          this.attachRefresh()
        }
      },

      close () {
        let result = false
        if (this.autoSave) {
          let curModified = this.modifiedItem(this.editedItem)
          if (curModified) {
            if (this.isChild()) {
              result = !this.validItem(this.editedItem)
              if (result) {
                this.showErrors()
              }
            } else if (!this.saveItem(this.editedItem)) {
              result = true
            }
          }
        }
        if (!result) {
          this.$emit('closed', this)
          // se remover as duas linhas abaixo da bug no FieldJoin
          this.editedItem = {}
          this.editedIndex = -1
          this.isEditingChild = false
          this.currentTab = 0
          for (let child of this.children) {
            result = child.close()
            if (result) {
              break
            }
          }
        }
        if (!result) {
          this.editing = result
        }
        return result
      },

      newRec () {
        return this.editedItem && this.editedItem.id === 0
      },

      modified () {
        if (this.deleteds.length > 0) return true
        for (let record of this.records) {
          if (record.__modified__) {
            return true
          }
        }
        return false
      },

      modifiedItem (item) {
        let index = this.getRecno(item)
        if (index < 0)
          return false
        return this.$options.sorted[index]['__modified__']
      },

      modifiedIndex (index) {
        if (index === -1 || this.$options.sorted.length === 0)
          return false
        let result = false
        let record = this.$options.sorted[index]
        if (!record)
          return false
        if (record['id'] === 0)
          result = true
        else {
          for (let key in record.__original__) {
            let field = this.fields[key]
            if (!(field && (field.readonly || field.noSave)) && !this.compareItem(index, key)) {
              result = true
              // console.log('Modificado: ' + this.name + "->" + key)
              break
            }
          }
          if (!result) {
            if (record['id'] === this.editedItem['id']) {
              for (let child of this.children) {
                if (child.modified()) {
                  // console.log('Modificado child: ' + child.name)
                  result = true
                  break
                }
              }
            }
          }
        }
        if (this.$options.sorted.length > 0 && record['__modified__'] !== result) {
          record['__modified__'] = result
          if (this.isChild())
            this.main.modifiedIndex(this.main.editedIndex)
        }
        return result
      },

      modifiedKey (key) {
        let result = ''
        let field = this.fields[key]
        if (!field.readonly) {
          if (!((this.editedIndex < 0) || this.newRec() || this.compareItem(this.editedIndex, key))) {
            if (field.type === 'password')
              result = '* Alterado *'
            else if (field.type === 'bool')
              result = '* Alterado *'
            else {
              let value = this.$options.sorted[this.editedIndex].__original__[key]
              if (value === null)
                result = '* Nulo *'
              else if (field.join)
                result = value
              else
                result = pretty(value, field, this.$options.sorted[this.editedIndex], false)
            }
          }
        }
        return result
      },

      compare (records, index, key) {
        if (['__modified__', '__valid__'].indexOf(key) >= 0)
          return true
        if (key.endsWith('__text'))
          return true
        if (key.endsWith('__extra'))
          return true
        let record = records[index]
        if (!record)
          return true
        let field = this.fields[key]
        let current = record[key]
        let original = record.__original__[key]
        if (current === null || current === undefined)
          current = ''
        if (original === null || original === undefined)
          original = ''
        if (field) {
          if (field.type === 'datetime') {
            current = prettyDatetime(current)
            original = prettyDatetime(original)
            if (original == 'NOW()' || original == 'NOW')
              original = current
          } else if (field.type === 'date') {
            current = prettyDate(current)
            original = prettyDate(original)
          } else if (field.type === 'float') {
            current = formatFloat(current, field.decimal)
            original = formatFloat(original, field.decimal)
          } else if (field.type === 'treeview') {
            if (typeof(current) === 'object') {
              current = (current || []).join('|')
            }
            if (typeof(original) === 'object') {
              original = (original || []).join('|')
            }
          } else if (field.type === 'bool') {
            if (['1', 'SIM', 'TRUE'].indexOf(String(current).toUpperCase()) >= 0)
              record[key] = current = true
            else
              record[key] = current = false

            if (['1', 'SIM', 'TRUE'].indexOf(String(original).toUpperCase()) >= 0)
              record.__original__[key] = original = true
            else
              record.__original__[key] = original = false
          }
        }
        let result = String(current).trim() === String(original).trim()
        return result
      },

      compareItem (index, key) {
        if (this.$options.sorted.length === 0)
          return true
        return this.compare(this.$options.sorted, index, key)
      },

      compareKey (key) {
        return this.compareItem(this.editedIndex, key)
      },

      prepareSave (records, deleteds) {
        // envia somente campos e registros alterados
        let sendRec = []
        for (let index in records) {
          if (records[index].__valid__) {
            let rec = {}
            let newRec = !records[index]['id']
            for (let key in records[index].__original__) {
              let value = records[index][key]
              if (value === undefined)
                value = null
              else if (value === 'NaN')
                value = null

              let field = this.fields[key]
              if (field && (!field.noSave) && (!field.readonly) && key !== '__original__' && key !== '__signature__' && 
                  ((newRec && value !== null) || !this.compare(records, index, key))) {
                if (value === true) {
                  value = 1
                } else if (value === false) {
                  value = 0
                } else if (field.type == 'datetime') {
                  if (value && value != 'NOW()' && value != 'NOW') {
                    value = dateFormat(value, 'yyyy-mm-dd HH:MM' + (field.seconds ? ':ss' : ''))
                  }
                } else if (value && field.type === 'treeview') {
                  value = (value || []).join('|')
                } else if (typeof (value) === 'object') {
                  value = this.formatSelectOut(value)
                }
                rec[key] = value
                if (this.fields[key] && this.fields[key].type === 'upload') {
                  let keyfile = key + '__file'
                  rec[keyfile] = records[index][keyfile]
                }
              }
            }
            if (Object.keys(rec).length > 0) {
              rec['id'] = newRec ? 'new' : records[index]['id']
              rec['__signature__'] = records[index]['__signature__']
              // rec['__original__'] = records[index]['__original__']
              sendRec.push(rec)
            }
          }
        }
        // registros excluidos
        let sendDel = []
        for (let item of deleteds) {
          if (item['id'])
            sendDel.push({ id: item['id'], '__signature__': item['__signature__'] })
        }
        return [sendRec, sendDel]
      },

      loadRecord (curRecord, recno, data) {
        this.formatSelectIn(curRecord)
        for (let record of [this.$options.sorted[recno]]) {
          for (let key in record) {
            if (record[key] && typeof(record[key]) === 'object') {
              let field = this.fields[key]
              if (field && field.type == 'datetime') {
                record[key] = dateFormat(record[key], 'yyyy-mm-dd HH:MM:ss')
              }
            }
          }
          record.__original__ = deepCopy(record, ['__signature__', '__file', '__url'])
          this.$set(record, '__modified__', false)
        }
        for (let col in curRecord) {
          let val = curRecord[col]
          let field = this.fields[col]
          if (field) {
            if (field.type == 'bool') {
              val = (!val || val == '0') ? false : true
            }
            else if (field.type == 'datetime' && val) {
              val = new Date(val)
            }
            else if (field.type === 'treeview' && val) {
              val = val.split('|')
            }
          }
          this.$options.sorted[recno][col] = val
          // comentando as linhas abaixo, as alteraçoes de outros usuarios nao serao percebidas
          // let oldval = this.$options.sorted[recno].__original__[col]
          // if (oldval == 'NOW()' || oldval == 'NOW') {
            this.$options.sorted[recno].__original__[col] = val
          // }
        }
        for (let child of this.children) {
          child.editing = false
          child.linkToParent(this.editedItem[child.parentId])
        }
        this.$emit('loaded', this, data)
      },

      sendSave (records, deleteds, searchText, recno, silence=false) {
        let vm = this
        if (this.execEvent('onPrepareSave')) {
          return true
        }

        // envia somente campos e registros alterados
        var [sendRec, sendDel] = this.prepareSave(records, deleteds)
        let empty = sendRec.length == 0 && sendDel.length == 0

        var children = []
        if (!this.isChild()) {
          for (let child of this.children) {
            let [sendRec2, sendDel2] = child.prepareSave(child.records, child.deleteds, null, null)
            children[child.name] = {
              records: sendRec2,
              deleteds: sendDel2,
              parentId: child.main.editedItem['id']
            }
            if (empty && (sendRec2.length > 0 || sendDel2.length > 0)) {
              empty = false
            }
          }
        }

        if (empty && !this.saveParams) {
          if (!silence) {
            this.alert('Nenhuma alteração para salvar')
          }
          return true // aborta
        }

        if (this.execEvent('onBeforeSave', sendRec, sendDel, children)) {
          return true
        }

        this.saving = true

        ajax.request(
          'crud.php', {
            name: this.name,
            service: 'save',
            records: sendRec,
            deleteds: sendDel,
            searchText: searchText,
            children: children,
            params: this.saveParams
          },
          response => {
            if (searchText)
              vm.loadRecords(response.data)
            else if (response.data.records)
              vm.loadRecord(response.data.records[0], recno)
            vm.saving = false
            if (this.execEvent('onAfterSave', sendRec, sendDel, children, response.data))
              return true
          },
          error => {
            vm.saving = false
            if (this.execEvent('onErrorSave', sendRec, sendDel, children, error))
              return true
            ajax.error(error)
          },
          null,
          this.host,
          true
        )
        this.saveParams = null
      },

      showErrors () {
        this.$store.commit('CAPTION_ERROR', 'Corrija os erros antes de Salvar:')
        let txtErr = ''
        for (let x in this.fields) {
          let field = this.fields[x]
          if (field && field.error) {
            txtErr += 'Erro em [' + field.text + ']: ' + field.error + '<BR/>'
          }
        }
        for (let child of this.children) {
          for (let x in child.fields) {
            let field = child.fields[x]
            if (field && field.error) {
              txtErr += child.singular + '.' + field.text + ' >> ' + field.error + '<BR/>'
            }
          }
        }
        this.error(txtErr || 'Corrija os erros antes de salvar! Eles estão marcados de "vermelho".')
      },

      saveItem (item = null, params = null, silence=false) {
        if (!item) item = this.editedItem
        let result = false
        if (!this.validItem(item)) {
          this.showErrors()
        } else {
          let index = this.getRecno(item)
          this.saveParams = params
          result = !this.sendSave([item], [], null, index, silence)
        }
        return result
      },

      saveIndex (index) {
        if (!this.validItem(this.$options.sorted[index])) {
          this.showErrors()
        } else {
          this.sendSave([this.$options.sorted[index]], [], null, index)
        }
      },

      async save (params = null) {
        if (this.valid()) {
          this.saveParams = params
          this.sendSave(this.records, this.deleteds, this.searchText, null)
        } else if (this.children.length > 0) {
          this.showErrors()
        } else if (await this.$confirm('<BR>Alguns itens estão inválidos. Somentes os itens válidos poderão ser salvos. Deseja continuar ?')) {
          for (let index in this.records) {
            if (this.records[index].__valid__)
              this.saveIndex(index)
          }
          this.saveParams = params
          this.sendSave([], this.deleteds, null, null)
          this.deleteds = []
        }
      },

      validation (field) {
        // este metodo é necessário devido "messages" ter preferencia a "rules"
        // tambem envia "editedItem" para "rules"
        let result = true
        if (field.type == 'bool') {
          field.type = 'bool'
        }
        if (this.editedIndex >= 0) {
          if (field.rulesAlways || this.modifiedIndex(this.editedIndex)) {
            for (let rule of field['rules']) {
              result = rule(this.editedItem[field.value], this.editedItem, field, this)
              if (result !== true) {
                if (field.error !== result)
                  field.error = result
                break
              }
            }
          }
          if (result === true && field['rules'].length > 0 && field.error !== '')
            field.error = ''
        } else {
          result = field.error
        }
        return (result === null || result === '') ? true : result
      },

      valid () {
        for (let item of this.records) {
          if (!item['__valid__']) {
            return false
          }
        }
        for (let child of this.children) {
          if (!child.valid()) {
            return false
          }
        }
        return true
      },

      validItem (item) {
        if (!item['__valid__']) {
          return false
        }
        for (let child of this.children) {
          if (!child.valid()) {
            return false
          }
        }
        return true
      },

      debounce (key, count, value, proc, params) {
        if (count >= this.$options.debounceList[key]) {
          // if (value !== this.$options.debounceLast[key]) {
            this.$options.debounceLast[key] = value
            this.$options.debounceList[key] = 0
            proc(params)
          // }
        }
      },

      clearDebounce () {
        this.$options.debounceLast = {}
      },

      nextDebounce (key) {
        this.$options.debounceList[key] = (this.$options.debounceList[key] || 0) + 1
        return this.$options.debounceList[key]
      },

      // onMount                                        -> params: vm, fields, sorted, columns, tabsTemp
      // onAfterMount                                   -> params: vm, fields, tabs
      // onEdit                                         -> params: vm, item
      // onClone                                        -> params: vm, item
      // *** return null ***
      // onBeforeNav, onAfterNav                        -> params: vm, item, index
      // onPrepareCreate, onBeforeCreate                -> params: vm
      // onAfterCreate                                  -> params: vm, item, index
      // onBeforeDelete, onAfterDelete                  -> params: vm, item, index
      // onPrepareSave                                  -> params: vm
      // onBeforeSave                                   -> params: vm, records, deleteds, children
      // onAfterSave                                    -> params: vm, records, deleteds, children, response.data
      // onErrorSave                                    -> params: vm, records, deleteds, children, error
      // onBeforeLink                                   -> params: vm, item, index
      // *** return true *** para cancelar a operação

      execEvent (key, item = null, index = null, children = null, error = null) {
        let current = this
        let event = current[key]
        let result = false
        while (current && !event) {
          current = current.$parent
          if (current)
            event = current[key]
        }
        if (event)
          result = event(this, item, index, children, error)
        if (result && typeof (result) === 'string')
          this.error(result)
        return result
      },

      getCurrent () {
        return this
      },

      isChild () {
        return !!this.main
      },

      getChild (name) {
        for (let child of this.children) {
          if (child.name === name)
            return child
        }
        return null
      },

      fieldChild (name, fieldname, prop = null, def = null) {
        let result = def || {}
        for (let child of this.children) {
          if (child.name === name) {
            result = child.fields[fieldname]
            if (prop)
              result = result ? result[prop] : def
            break
          }
        }
        return result
      },

      countChild (name) {
        let result = '?'  // crud ainda nao foi montada
        for (let child of this.children) {
          if (child.name === name) {
            result = child.records.length
            break
          }
        }
        return result
      },

      sumChild (name, fieldname) {
        let result = '?'
        for (let child of this.children) {
          if (child.name === name) {
            result = 0
            for (let record of child.records) {
              result += toFloat(record[fieldname])
            }
            break
          }
        }
        return result
      },

      multChild (name, mult, inc = [], dec = []) {
        let result = '?'
        for (let child of this.children) {
          if (child.name === name) {
            result = 0
            for (let record of child.records) {
              if (mult.length > 0) {
                let item = 1
                for (let key of mult)
                  item *= toFloat(record[key])
                result += item
              }
              for (let key of inc)
                result += toFloat(record[key])
              for (let key of dec)
                result -= toFloat(record[key])
            }
            break
          }
        }
        return result
      },

      shareChild (name, share, total, fieldname, mult, decimal = 2) {
        let sum = 0
        for (let child of this.children) {
          if (child.name === name) {
            share = toFloat(toFixed(share, decimal))
            total = toFloat(toFixed(total, decimal))
            let len = child.records.length
            let count = 0
            for (let record of child.records) {
              let itemValue = 1
              count += 1
              for (let key of mult)
                itemValue *= toFloat(record[key])
              if (sum >= share)
                record[fieldname] = 0
              else if (len === count)
                record[fieldname] = toFixed(share - sum, decimal)
              else
                record[fieldname] = toFixed(share * (itemValue / total), decimal)
              sum += toFloat(record[fieldname])
            }
            break
          }
        }
        return sum
      },

      addSearch () {
        let sel = this.selector
        let column = sel.table + '.' + sel.field
        let value = sel.value

        if (sel.isBool) {
          if (value)
            value = '1'
          else
            value = '0'
        } else if (sel.isDate) {
          if (sel.function) {
            let func = {
              'Ano': 'YEAR',
              'Mês': 'MONTH',
              'Dia': 'DAY'
            }
            if (sel.function === 'Hoje') {
              value = today()
            }
            column = (func[sel.function] || '') + '(DATE(' + column + '))'
          } else {
            let [year, month, day] = value.split('-')
            if (day === undefined)
              [day, month, year] = value.split('/')
            let aammdd = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            let ok = new Date(aammdd)
            if (ok.toString() === 'Invalid Date') {
              sel.error = 'Data inválida'
              return false
            }
            value = '"' + aammdd + '"'
          }
        } else if (!value) {
          sel.error = 'Valor obrigatório'
          return false
        } else {
          value = '"' + value + '"'
        }

        let comp = {
          'E': '',  // 'AND '
          'OU': 'OR '
        }
        if (this.searchText.length > 0)
          comp = comp[sel.comp]
        else
          comp = ''

        let op = {
          'igual': '=',
          'diferente': '!=',
          'contém': '%%',
          'maior': '>',
          'maior ou igual': '>=',
          'menor': '<',
          'menor ou igual': '<=',
          'inicia': '>>',
          'termina': '<<'
        }

        sel.error = null
        sel.value = ''
        this.searchLast = []
        this.searchText.push(comp + column + op[sel.op] + value)
        this.selector.show = false
      },

      itemsRefresh (item) {
        if (item.itemsRefresh) {
          var field = item
          item.loading = true
          ajax.request('crud.php', { name: this.name, service: 'populate', key: item.value },
            response => {
              if (response.data.populate) {
                let items = []
                for (let pop of response.data.populate) {
                  let popValues = { value: pop.id, text: pop.nome }
                  if (pop.extra)
                    popValues['extra'] = pop.extra.split('^&')
                  items.push(popValues)
                }
                field.items = items
                field.loading = false
              }
            },
            null,
            null,
            this.host,
            true
          )
        } else {
          item.appendOuterClick(this.editedItem, item, this)
        }
      },

      onBlur (field) {
        if (field.type === 'float' && field.fixDecimal) {
          let val = this.editedItem[field.value]
          if (val) {
            let valF = toFixed(val, field.decimal)
            if (val !== valF)
              this.editedItem[field.value] = valF
          }
        }
      },

      tabKey (key) {
        this.showTabs = true
        return this.tabs.length + key
      },

      pathArchive (event) {
        let link = '/historico'
        let search = '?tabela="' + this.selector.tables[0] + '" AND tabela_id=' + this.editedItem.id
        this.$store.commit('JOIN_LINK', link)
        document.body.setAttribute('style', 'cursor: wait;')
        if (event.ctrlKey) {
          setCookie('Authorization', store.state.token)
          setCookie('Instance', store.state.instance)
          setCookie('clientVersion', store.state.clientVersion)
          let route = this.$router.resolve(link + search)
          window.open(route.href, '_blank')
        } else {
          this.$router.push(link + search)
        }
      },

      canArchive () {
        return hasGrant('/historico')
      },

      customFilter (value, search, item) {
        // calcPagination nao esta ignorando os itens ocultos
        let result = true
        if (this.filterTable){
          result = this.filterTable(value, search, item)
        }
        return result
      },

      isDark () {
        let app = this.$root.$children[0].appearance
        return app ? app.dark : false 
      },

      hasGrant (field) {
        return hasGrant(field.link)
      },

      toLink (event, field, id=null) {
        if (field.link) {
          let search = id || this.editedItem[field.value] || ''
          if (field.multiple) {
            search = '?-.id IN (' + (search === '' ? 'null' : search) + ')'
          } else {
            search = '?-.id=' + (search === '' ? 'null' : search)
          }
          toLink(event, this.$router, field.link, search)
        }
      },

      toLinkDis (event, field) {
        if (field.disable)
          this.toLink(event, field)
      },

      disabledStyle (field) {
        // if (this.$refs) {
        //   let cl = this.$refs['_select_' + field.value]
        //   if (cl) {
        //     cl = cl[0].$el.classList
        //     cl.remove('v-input--is-disabled')
        //     if (cl.contains('v-input--is-readonly')) {
        //       setTimeout(function () { cl.add('v-input--is-disabled') }, 300)
        //     } else {
        //       setTimeout(function () { cl.remove('v-input--is-disabled') }, 300)
        //     }
        //   }
        // }
        return field.text
      },

      msgNoSave () {
        this.alert('Será necessário salvar todas as alterações e não somente deste items. Por favor, utilize o botão [Salvar] no topo da tela.')
      },

      attachRefresh () {
        let myAttach = this.$refs.myAttach
        if (myAttach)
          myAttach.reset()
      },

    captionBtn (item, field, vm) {
        if (!field.btn.caption)
          return ''
        if (typeof (field.btn.caption) === 'string')
          return field.btn.caption
        return field.btn.caption(item, field, vm)
      },

      styleBtn (item, field, vm) {
        if (!field.btn.style)
          return ''
        if (typeof (field.btn.style) === 'string')
          return field.btn.style
        return field.btn.style(item, field, vm)
      },

      changeIsSelected (props) {
        let i = this.isSelected.indexOf(props.item)
        if (props.isSelected) {
          if (i === -1)
            this.isSelected.push(props.item)
        } else {
          if (i >= 0)
            this.isSelected.splice(i, 1)
        }
        this.$emit('changeSelected', props.isSelected, props.item, this.fields, this) // (val, item, field, vm)
      },

      changeComponent (event, item, key) {
        item[key] = event.target.value
      },

      clickComponent (event, field, item) {
        if (field.component.click) {
          field.component.click(this, field, item, event)
        }
      },

      getFavorite () {
        // let search = ''
        // if (this.searchText.length > 0) {
        //   for (let val of this.searchText)
        //     search += '|' + val
        //   search = search.substr(1)
        //   if (search.startsWith('s='))
        //     search = '?' + search
        //   else if (search)
        //     search = '?s=' + search
        // }
        let baseUrl = process.env.BASE_URL
        let url = window.location.pathname
        if (baseUrl !== '/') {
          url = url.substr(baseUrl.length)
          if (url[0] != '/') {
            url = '/' + url
          }
        }
        for (let index in this.favorites) {
          let item = this.favorites[index]
          if (item.url === url) // && item.search === search)
            return index
        }
        return -1
      },

      addFavorite () {
        let index = this.getFavorite()
        if (index >= 0) {
          this.$store.commit('DEL_FAVORITE', index)
        } else {
          let name = this.caption
          let search = ''
          // if (this.searchText.length > 0) {
          //   for (let val of this.searchText)
          //     search += '|' + val
          //   search = '?s=' + search.substr(1)
          //   name += ': ' + search.substr(3)
          // }
          this.$store.commit('ADD_FAVORITE', {
            name: name,
            url: window.location.pathname,
            search: search
          })
        }
      },

      configFav () {
        this.isFavorite = this.getFavorite() >= 0
      },

      canEdit (item) {
        let result = true
        if ((!this.showSelect) && (!this.showExpand)) {
          if (this.execEvent('onEdit', item))
            result = false
          if (result)
            result = this.myEditable && this.editItem(item, false)
          if (!result && this.attachInGrid) {
            this.editedIndex = this.getRecno(item)
            this.editedItem = item
          }
        }
        return result
      },

      incIndex (val) {
        if (val) {
          // avanca um item
          if (val == 1) {
            if (this.editedIndex >= (this.$options.sorted.length - 1))
              this.incPage(1)
            else
              this.editIndex(this.editedIndex + 1)
          // avanca para ultimo item
          } else if (val > 1) {
            this.incPage(2)
          // retrocede um item
          } else if (val == -1) {
            if (this.editedIndex <= 0)
              this.incPage(-1)
            else
              this.editIndex(this.editedIndex - 1)
          // retrocede para o primeiro item
          } else if (val < -1)
            this.incPage(-2)
        }
      },

      incPage (val) {
        if (val) {
          let page, recno
          // avanca uma pagina
          if (val == 1) {
            page = this.pagination.page + 1
            recno = 0
          }
          // avanca para ultima pagina
          else if (val > 1) {
            page = this.pagination.length
            recno = this.pagination.rowsPerPage
          }
          // retrocede uma pagina
          else if (val == -1) {
            page = this.pagination.page - 1
            recno = this.pagination.rowsPerPage
          }
          // retrocede para a primeira pagina
          else if (val < -1) {
            page = 1
            recno = 0
          }

          if (page > 0 && page <= this.pagination.length)
            this.moveToPage(page, recno)
        }
      },

      moveToPage (page, recno) {
        this.rePage = recno
        this.pagination.page = page
        this.close()
      },

      comboItemDisabled (item, field) {
        if (field.itemsDisabled)
          return field.itemsDisabled(item, this.getCurrent())
        else
          return item.disabled
      },

      closeSearchBox () {
        if (this.selector)
          this.selector.show = false
      },

      execSoma () {
        for (let x of this.somaKeys) {
          this.somaVal[x] = 0
        }
        for (let record of this.records) {
          for (let x of this.somaKeys) {
            this.somaVal[x] += toFloat(record[x])
          }
        }
        this.somatoria = true
      },

      functionClass (item, field) {
        let result = ''
        if (field.btn && field.btn.class) {
          result = field.btn.class(item, field, this)
        }
        return result
      }, 

      replication () {
        if (this.replicSelected.length == 0) {
          this.alert('Selecione pelo menos um campo')
          return
        }

        let item = this.editedItem
        let close = 0
        this.dReplicate = false
        for (let field of this.replicSelected) {
          for (let record of this.records) {
            let curvalue = record[field.name]
            if (this.fields[field.name].join) {
              curvalue = parseInt(curvalue)
            }
            if (((!this.replicNull) || (!curvalue)) &&
                (record[field.name] != item[field.name])
            ) {
              record[field.name] = item[field.name]
              let items = this.fields[field.name].items
              if (items && items.length > 0) {
                record[field.name + '__text'] = findValue(items, item[field.name])
              }
              else if (this.fields[field.name].join) {
                record[field.name + '__text'] = item[field.name + '__text']
              }
              record['__modified__'] = true
              close += 1
            }
          }
        }
        if (close > 0) {
          this.alert(close + ' campos foram replicados')
          this.close()
        } else {
          this.alert('Nenhum campo foi replicado')
        }
      },

      expandItem (item) {
        let idx = this.isExpanded.indexOf(item)
        if (idx >= 0) {
          this.isExpanded.splice(idx, 1)
        } else {
          this.isExpanded.push(item)
        }
      },

      isExpandedItem (item) {
        return this.isExpanded.indexOf(item) >= 0
      },

      flagColor (item) {
        let cor
        if (item.flag_id && this.populate.flag_id) {
          for (let flag of this.populate.flag_id) {
            if (flag.value == item.flag_id) {
              cor = flag.extra[0]
              break
            }
          }
        }
        return cor
      },

      flagClick (item) {
        this.flagId = item.flag_id
        this.flagText = item.flag
      },

      salvarFlag (item) {
        let salvar = false
        if (item.flag_id != this.flagId) {
          item.flag_id = this.flagId
          salvar = true
        }
        if (item.flag != this.flagText) {
          item.flag = this.flagText
          salvar = true
        }
        if (salvar) {
          if (this.isChild()) {
            this.main.saveItem()
          } else {
            this.saveItem(item)
          }
        }
        item.flagMenu = false
      }

      // filterSearch (item, queryText, itemText) {
      //   return true
      // },

      // teste (evt) {
      //   evt.target.selectionStart
      // }
    }
  }
</script>

<style scoped>
  .v-text-field {
    font-size: 0.8rem;
  }
  table.v-table thead tr {
    height: 40px;
  }
  .v-toolbar__title {
    font-size: 1.2rem;
    padding-left: 0 !important;
  }
  .toolbar-menu {
    box-shadow: none;
    top: 44px;
    overflow-y: inherit;
    overflow-x: inherit;
    contain: inherit;
  }
  .v-card__title {
    padding-bottom: 0px;
  }
  .v-card__text {
    padding-top: 0px;
  }
  .selected_row {
    background-color: aliceblue;
  }
  .v-messages__message {
    margin-bottom: 12px;
    font-size: smaller;
  }
  .my-search {
    margin: 0;
    padding: 0 10px;
  }
  .v-input__slot {
    margin-bottom: 3px;
  }
  .card-saving {
    padding-top: 10px;
  }
  .my-save {
    padding-right: 5px;
  }
  .my-pagination {
    padding: 0;
    margin: 0 20px 30px 0;
    border-radius: 4px;
    /* background-color: rgb(240, 240, 240); */
    width: 90%;
    max-width: 500px;
    height: 42px;
    float: right;
  }
  .my-selector {
    position: absolute;
    float: top;
    top: 100px;
    margin: 0 15%;
    z-index: 9;
  }
  .elevation-1 {
    -webkit-box-shadow: inherit !important;
    box-shadow: inherit !important;
  }
  .my-attach {
    margin: 30px 20px 40px 20px;
    border-radius: 3px; 
    border: 1px solid #D2D6DE;
  }
  .v-data-table--dense td {
    font-size: 0.8rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;
  }
  .my-data-table {
    border-radius: 3px; 
    border: 1px solid #D2D6DE;
  }
  .my-tabs {
    margin-top: 12px;
    border-radius: 3px;
  }
  .my-group {
    float: left;
    transform: rotate(270deg);
    transform-origin: bottom;
    color: white;
    background-color: #FF7F50;
    padding: 3px 8px 0px 8px;
    margin: 0px -20px 0px 0px;
    line-height: 16px;
    border-radius: 4px;
    min-width: 50px;
    text-align: center;
  }
  .my-font {
    font-size: 0.8rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .scrolling-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    height: auto !important;
    -webkit-overflow-scrolling: touch;  /* suaviza scroll no iOS */
  }
  .scrolling-item {
    display: inline-block;
  }
  /* .scrolling-item > * {
    flex-wrap: wrap !important;
  } */
  ::-webkit-scrollbar {
      height: 4px;
  }
  ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 3px;
      border-radius: 3px;
      background: #fde405a8;
      -webkit-box-shadow: inset 0 0 6px rgba(216, 253, 9, 0.678);
      /* background: #8e8e8e;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); */
  }
  .img-input {
    top: -100px;
    position: relative;
    margin-left: 60px;
  }
  .mytr-hover:hover {
    background-color: #e7e7e7;
  }
  .replicTD {
    white-space: nowrap;
  }
</style>

<style>
  .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
    max-height: 24px;
  }
  .v-text-field__prefix {
    margin-top: -2px;
  }
  .v-expansion-panel__header {
    font-weight: bolder;
    font-style: oblique;
  }
  input[type="number"], .myNumber input {
    text-align: right;
  }
  .v-expansion-panel-content {
    display: contents;
  }
  .v-tabs-bar.theme--dark .v-tab:not(.v-tab--active):not(.v-tab--disabled) {
    color: white;
  }
  .v-expansion-panel-content {
    overflow: auto;
  }
  td .v-input--selection-controls.v-input {
    margin-top: 0;
    height: 32px;
  }
  .v-pagination__item {
    font-size: 12px;
    height: 24px;
  }
</style>
