<template>
  <div @click="onClick">
    <v-autocomplete
      ref="fieldjoin"
      v-model.trim="select"
      :items="items"
      :label="label"
      :placeholder="placeholder"
      :disabled="disabled"
      :error-messages="error"
      :hint="hint"
      :messages="messages"
      :rules="rules"
      :required="required"
      :search-input.sync="search"
      :loading="loading"
      :prefix="prefix"
      :suffix="suffix"
      :prepend-inner-icon="hasGrant()"
      @click:prepend-inner="toLink"
      :append-outer-icon="appendOuterIcon"
      @click:append-outer="appendOuterClick ? appendOuterClick(editedItem, null, getVm()) : () => {}"
      no-filter
      auto-select-first
      :prepend-icon="prependIcon"
      :dark="dark"
    >
      <template v-slot:selection="data">
        <!--<v-chip small v-if="data.item.value">-->
        <span v-if="data.item.value" style="flex-wrap: inherit;">
          <v-avatar class="accent white--text">
            {{ extractId(data.item.value) }}
          </v-avatar>
          <span>
            {{ data.item.text }}
          </span>
        </span>
        <!--</v-chip>-->
      </template>

      <template v-slot:item="data">
        <!--<v-chip small v-if="data.item.value">-->
        <span v-if="data.item.value" style="flex-wrap: inherit; font-size: smaller;">
          <v-avatar class="accent white--text">
            {{ data.item.value }}
          </v-avatar>
          {{ data.item.text }}
        <!--</v-chip>-->
        </span>
        <span v-else />
      </template>

      <template v-slot:no-data>
        <span class="no-data">Nenhum item encontrado</span>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
  import ajax from '@/js/base/ajax'
  import { hasGrant, toLink } from '@/js/base/misc'

  export default {
    props: [
      'name',
      'placeholder',
      'editedItem',
      'editedKey',
      'type',
      'label',
      'disabled',
      'hint',
      'errorMessages',
      'messages',
      'rules',
      'required',
      'mask',
      'join',
      'link',
      'prefix',
      'suffix',
      'appendOuterIcon',
      'appendOuterClick',
      'prependIcon',
      'dark',
    ],

    data: () => ({
      loading: false,
      items: [],
      extra: [],
      search: null,
      select: null,
      error: '',
      crud: null
    }),

    mounted () {
      // if (this.label.endsWith(' id')) this.label2 = this.label.substr(0, this.label.length - 3)
      // else if (this.label.endsWith(' id *')) this.label2 = this.label.substr(0, this.label.length - 4) + '*'
      if (this.editedItem)
        this.refresh(this.editedItem)
    },

    watch: {
      select (val) {
        if (val !== 'null' && val !== 'undefined') {
          this.editedItem[this.editedKey] = val
          for (let item of this.items) {
            if (item.value === val) {
              this.editedItem[this.editedKey + '__text'] = item.text
              this.editedItem[this.editedKey + '__extra'] = item.extra
              break
            }
          }
        }
      },

      editedItem: {
        handler: function (item) { this.refresh(item) },
        deep: true
      },

      errorMessages () {
        this.error = this.errorMessages
      },

      search (val) {
        this.seek(val, false)
      }
    },

    methods: {
      seek (val, now = true) {
        if (!val) return
        var that = this
        val = (val || '').trim()

        // eslint-disable-next-line
        function procWithDelay (params, val = null, set = true) {
          that.loading = true
          ajax.request('crud.php', params,
            response => {
              // that.items.splice(0, that.items.length)
              for (let x in response.data.records) {
                let record = response.data.records[x]
                let text = ''
                let extra = []
                let extraNow = false
                for (let key in record) {
                  if (key === '__extras__')
                    extraNow = true
                  else if (extraNow)
                    extra.push(record[key])
                  else if (key !== 'id')
                    text += record[key] + ' | '
                }
                text = text.substr(0, text.length - 3)
                that.items.push({ value: String(record.id), text: text, extra: extra })
                if (val && set)
                  that.select = val
              }
            },
            null,
            () => {
              that.loading = false
            }
          )
        }

        let vm = this.getVm()
        that.items.splice(0, that.items.length)
        let fieldname = this.join
        let args = null
        if (typeof (this.join) === 'function')
          [fieldname, args] = this.join(val, vm)
        let name = this.name || ((vm && vm.name) ? vm.name : 'main')
        let params = { name: name, service: 'join', searchText: val, fieldname: fieldname, args: args }
        let key = 'join_' + this.join
        if (now || !vm)
          procWithDelay (params, val, !!vm)
        else
          setTimeout(vm.debounce, 700, key, vm.nextDebounce(key), val, procWithDelay, params)
      },

      clear () {
        this.items.splice(0, this.items.length)
        this.select = null
      },

      getVm () {
        return this.crud ? this.crud : this.getDebounce()
      },

      extractId (val) {
        let pos = (val || '').indexOf(' | ')
        if (pos >= 0) {
          val =  val.substr(0, pos)
          this.editedItem[this.editedKey] = val
        }
        // let cl = this.$children[0].$el.classList
        // cl.remove('v-input--is-disabled')
        // if (cl.contains('v-input--is-readonly')) {
        //   setTimeout(function () { cl.add('v-input--is-disabled') }, 300)
        // } else {
        //   setTimeout(function () { cl.remove('v-input--is-disabled') }, 300)
        // }
        return val
      },

      refresh (item) {
        if (item[this.editedKey] !== this.select) {
          this.items.splice(0, this.items.length)
          let val = String(item[this.editedKey])
          this.items.push({
            value: val,
            text: item[this.editedKey + '__text'],
            extra: item[this.editedKey + '__extra']
          })
          this.select = val
        }
      },

      getDebounce () {
        let current = this
        while (current && !current.debounce)
          current = current.$parent
        return current
      },

      hasGrant () {
        return hasGrant(this.link)
      },

      toLink (event) {
        if (this.link) {
          let search = ''
          // if (this.select)
            search = '?-.id=' + (this.select === '' ? 'null' : this.select)
          toLink(event, this.$router, this.link, search)
        }
      },

      onClick (event) {
        if (this.disabled)
          this.toLink(event)
      }
    }
  }
</script>

<style scoped>
  .no-data {
    padding-left: 5px;
  }
  .v-avatar {
    width: 48px !important;
    height: 16px !important;
    border-radius: 20px !important;
  }
  .v-list__tile--link {
    height: 32px !important;
  }
</style>

<style>
  .v-select__selections {
    flex-wrap: inherit;
  }
</style>
