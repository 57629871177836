<template>
  <div>
    <v-text-field
      v-model="filename"
      ref="fileTextField"
      :label="label"
      :required="required"
      :disabled="disabled"
      clearable
      prepend-inner-icon="mdi-paperclip"
      :append-icon="url ? 'mdi-file-download' : ''"
      @click.native="onFocus"
      @keydown="breakKey"
      @input="onClear"
      @click:append="onDownload"
    />
    <input
      type="file"
      :accept="accept"
      :multiple="false"
      :disabled="disabled"
      ref="fileInput"
      @change="onFileChange"
    >
  </div>
</template>

<script>
  import { saveAs } from 'file-saver'

  export default{
    props: {
      value: { type: [Array, String] },
      accept: { type: String, default: '*' },
      editedItem: { type: Object },
      editedKey: { type: String },
      label: { type: String, default: 'Selecione um arquivo ...' },
      hint: { type: String, default: '' },
      errorMessages: { type: String, default: '' },
      messages: { type: String, default: '' },
      rules: { default: [] },
      prefix: { type: String, default: '' },
      suffix: { type: String, default: '' },
      required: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      loading: { type: Boolean, default: false },
      multiple: {
        type: Boolean, // not yet possible because of data
        default: false
      }
    },

    data: () => ({
      filename: '',
      code: null
    }),

    watch: {
      value (val) {
        this.filename = val
      }
    },

    computed: {
      url () {
        return this.editedItem[this.editedKey + '__url']
      }
    },

    mounted () {
      this.filename = this.value
    },

    methods: {
      onFocus () {
        if (!this.disabled) {
          this.$refs.fileInput.click()
        }
      },

      onClear () {
        if (!this.filename) {
          this.editedItem[this.editedKey] = ''
          this.editedItem[this.editedKey + '__file'] = null
          this.editedItem[this.editedKey + '__url'] = null
        }
      },

      onFileChange (event) {
        const files = event.target.files || event.dataTransfer.files
        if (files && files.length > 0) {
          this.filename = [...files].map(file => file.name).join(', ')
          const reader = new FileReader()
          var that = this
          reader.onload = function (event) {
            that.editedItem[that.editedKey + '__file'] = btoa(event.target.result)
            that.editedItem[this.editedKey + '__url'] = null
          }
          reader.readAsBinaryString(files[0])
        }
        this.$emit('input', this.filename)
      },

      onDownload () {
        let url = this.editedItem[this.editedKey + '__url']
        if (url) {
          url = this.$store.state.serverURI + url
          saveAs(url, this.filename)
        }
      },

      breakKey (event) {
        if (event.key !== 9 /* TAB */) event.preventDefault()
      }
    }
  }
</script>

<style scoped>
  input[type=file] {
    position: absolute;
    left: -99999px;
  }
</style>
